const sb = require('@sb/util')
import {EditorState, Plugin, TextSelection} from 'prosemirror-state'
import {Decoration, DecorationSet, EditorView} from 'prosemirror-view'
import {schema as basicSchema} from 'prosemirror-schema-basic'
import {Node, Schema, Slice} from 'prosemirror-model'
import {buildKeymap} from 'prosemirror-example-setup'
import {dropCursor} from 'prosemirror-dropcursor'
import {gapCursor} from 'prosemirror-gapcursor'
import {history} from 'prosemirror-history'
import {keymap} from 'prosemirror-keymap'
import {
	chainCommands,
	baseKeymap,
	newlineInCode,
	createParagraphNear,
	liftEmptyBlock,
	splitBlock,
} from 'prosemirror-commands'

let mySchema = new Schema({
	nodes: basicSchema.spec.nodes.append({
		mention: sb.mentionNodeSpec,
		emoji: sb.emojiNodeSpec,
	}),
	marks: basicSchema.spec.marks,
})

const ImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml']

const EmojiList =
	'like unlike angry confused crying grinning heart-eyes neutral sleepy sad smiling tongue-out tired surprised wink'.split(
		' ',
	)

function findTextPosition(doc, pos) {
	const isCharacter = (char) => /\S/.test(char) // character is not " " will be in a word

	let start = pos,
		end = pos
	while (start > 0 && isCharacter(doc.textBetween(start - 1, start))) {
		start--
	}
	while (end < doc.nodeSize && isCharacter(doc.textBetween(end, end + 1))) {
		end++
	}

	return {start, end}
}
export default {
	name: 'TicketCommentInput',
	props: [
		'initMessage',
		'placeholder',
		'useEmoji',
		'useMention',
		'useUpLoadFile',
		'useSubmitButton',
		'acceptAttachment',
	],
	data() {
		return {
			openEmoji: false,

			isMentioning: false,
			forceShow: false,
			start: '@',
			mentionQuery: '',
			currentSelectedIndex: 0,
			filteredActions: [],
			mentionOffset: 0,

			suggestStyle: {},
		}
	},
	mounted() {
		this.createCommentEditor()
		this.applyMessage(this.initMessage)
		this.updateFuse()

		store.onAccount(this, () => {
			this.updateFuse()
			this.$forceUpdate()
		})
		document.body.addEventListener('keydown', this.onKeydown)
	},

	destroyed() {
		document.body.removeEventListener('keydown', this.onKeydown)
	},

	watch: {
		// reset editor if message = {}
		initMessage: function (newVal, oldVal) {
			if (lo.size(newVal) === 0) this.applyMessage(newVal)
		},
	},
	methods: {
		createCommentEditor() {
			let text_plugin = new Plugin({
				props: {
					handleTextInput(view, from, to, text) {
						const {state, dispatch} = view
						const urlRegex = /https?:\/\/[^\s]+/g
						const tr = state.tr
						if (view.composing) {
							return false // Don't interrupt ongoing composition
						}
						tr.insertText(text, from, to)

						const {start, end} = findTextPosition(state.doc, from)
						const textContent = state.doc.textBetween(start, end, ' ')
						if (urlRegex.test(textContent)) {
							const markLink = state.schema.marks.link.create({href: textContent})
							tr.addMark(start, end, markLink)
							dispatch(tr)
							return true
						}
						return false
					},

					handlePaste(view, event) {
						const text = event.clipboardData.getData('text/plain')
						const urlRegex = /https?:\/\/[^\s]+/g
						if (urlRegex.test(text) && text.startsWith('http')) {
							const linkMark = view.state.schema.marks.link.create({href: text})
							const node = view.state.schema.text(text, [linkMark])
							const fragment = Slice.fromJSON(view.state.schema, {content: [node.toJSON()]})
							const tr = view.state.tr.replaceSelection(fragment)
							view.dispatch(tr)
							return true
						}
						return false
					},

					handleKeyDown(view, event) {
						let {state} = view
						let {from} = state.selection
						if (event.key === '@') {
							self.openMentioning(from)
						}
						return false
					},
				},
			})

			let image_plugin = new Plugin({
				props: {
					handlePaste(view, event) {
						const items = event.clipboardData && event.clipboardData.items
						if (items) {
							for (let i = 0; i < items.length; i++) {
								if (items[i].type.indexOf('image') === 0) {
									event.preventDefault()
									self.uploadLocalFiles(event)
									view.focus()
									return true
								}
							}
						}
						return false
					},
				},
			})
			let state = EditorState.create({
				schema: mySchema,
				plugins: [
					keymap(buildKeymap(mySchema)),
					keymap(baseKeymap),
					keymap({
						'Shift-Enter': chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlock),
					}),
					dropCursor(),
					gapCursor(),
					history(),
					text_plugin,
					image_plugin,
				],
			})

			var self = this
			this.editor = new EditorView(this.$refs.editor, {
				state: state,
				dispatchTransaction(transaction) {
					let newState = self.editor.state.apply(transaction)
					self.editor.updateState(newState)
					self.onEditorChange()
					return false
				},
			})

			this.editor.focus()
		},

		doMessageChange() {
			let message = this.getMessage()
			this.$emit('change', message)
		},

		onEditorChange: lo.debounce(function () {
			this.doMessageChange()
		}, 200),

		applyMessage(message) {
			if (!message) return
			const {state, dispatch} = this.editor
			let doc = null
			if (!message.block) {
				doc = state.schema.node('doc', null, [state.schema.node('paragraph')])
			} else {
				const content = sb.blockToProseMirror(message.block) // get content convert to prosemirror
				doc = Node.fromJSON(mySchema, {type: 'doc', content: content})
			}
			const transaction = state.tr.replaceWith(0, state.doc.content.size, doc)
			dispatch(transaction)
		},

		getMessage() {
			const docContent = this.editor.state.doc.content.toJSON()
			let block = sb.proseMirrorToBlock(docContent)
			let message = {block, format: 'block'}
			if (lo.get(this.initMessage, 'attachments', []).length > 0) {
				message.attachments = this.initMessage.attachments
			}

			// ignore empty message
			if (!message.text && lo.size(message, 'attachments') === 0) return
			if (this.$refs.file_input) this.$refs.file_input.value = ''
			this.filterMentionQuery()
			return message
		},

		onKeydown(e) {
			//if (!this.isMentioning) return
			//insert ascii character and spacebar to mentionQuer
			if (e.code == 'Space' && this.mentionQuery && this.isMentioning) {
				// time out to prevent insert before doc change
				setTimeout(() => this.spaceToApplyFirstSuggestion(), 200)
				return
			}
			switch (e.keyCode) {
				case 38: // UP
					if (!this.isMentioning) return
					this.currentSelectedIndex--
					if (this.currentSelectedIndex < 0) this.currentSelectedIndex = lo.size(this.filteredActions) - 1
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				case 40: // DOWN
					if (!this.isMentioning) return
					this.currentSelectedIndex++
					if (this.currentSelectedIndex >= lo.size(this.filteredActions)) this.currentSelectedIndex = 0
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				case 27: // ESC
					if (!this.editor.hasFocus()) break
					this.clearMentions()
					break
				case 13: // ENTER
					if (!this.editor.hasFocus()) break
					var action = this.filteredActions[this.currentSelectedIndex]
					if (action) this.applySuggestion(action)
					break
				default:
					break
			}
		},

		updateFuse() {
			let actions = []
			let agents = store.matchAgent()
			lo.map(agents, (ag) => {
				if (ag.state !== 'active') return
				if (ag.type !== 'agent') return
				actions.push({text: sb.getAgentDisplayName(ag), action: 'mention', agent_id: ag.id})
			})

			this.allActions = actions
		},

		openMentioning(pos) {
			if (pos > 1) {
				let doc = this.editor.state.doc
				let beforeChar = doc.textBetween(pos - 1, pos, ' ')
				if (/\S/.test(beforeChar)) return
			}
			const selection_pos = this.editor.coordsAtPos(pos)

			let $wrapper = this.$refs.wrapper
			let rect = $wrapper ? $wrapper.getBoundingClientRect() : {}
			let {bottom = 0, left = 0} = rect
			let suggest_left = selection_pos.left - left
			let suggest_bottom = bottom - selection_pos.bottom + 20
			let style = {left: `${suggest_left}px`, bottom: `${suggest_bottom}px`}

			this.suggestStyle = style
			this.mentionOffset = pos
			this.mentionQuery = '@'
			this.toggleFilteredActions()
			return
		},

		spaceToApplyFirstSuggestion() {
			if (this.mentionQuery) {
				var action = this.filteredActions[this.currentSelectedIndex]
				if (action) this.applySuggestion(action)
			}
			return
		},

		filterMentionQuery() {
			let pos = this.mentionOffset
			let doc = this.editor.state.doc

			if (!this.mentionQuery || doc.textBetween(pos, pos + 1) != '@') {
				this.clearMentions()
				return
			}

			let {state} = this.editor
			let {from} = state.selection
			let text = this.mentionQuery
			if (pos < from) text = doc.textBetween(pos, from)
			if (!text.startsWith('@')) text = ''
			this.mentionQuery = text
			this.toggleFilteredActions()
		},

		async toggleFilteredActions() {
			if (!this.useMention) return
			let query = this.mentionQuery
			if (!query.startsWith(this.start) || query.endsWith(' ')) {
				this.clearMentions()
				return
			}
			this.isMentioning = true
			// clean this.start if user has deleted it
			if (this.forceShow) this.isMentioning = true
			this.forceShow = false
			query = query.substr(this.start.length, query.length - this.start.length)
			// smart trigger
			this.filteredActions = lo.filter(this.allActions, (action) => {
				let text = sb.unicodeToAscii(action.text || '').toLowerCase()
				let helpText = sb.unicodeToAscii(action.help_text || '').toLowerCase()
				let search = sb.unicodeToAscii(query).toLowerCase()
				return text.indexOf(search) > -1 || helpText.indexOf(search) > -1
			})
			lo.each(this.foundUsers, (hit) => {
				this.filteredActions.push({
					text: hit.name,
					action: 'mention',
					user_id: hit.document_id,
				})
			})

			if (this.filteredActions.length === 0) this.clearMentions()
		},

		toggleEmoji() {
			this.editor.focus()
			this.openEmoji = !this.openEmoji
		},

		hideEmoji() {
			this.openEmoji = false
		},

		renderEmojiPopup() {
			if (!this.openEmoji) return null

			let $items = lo.map(EmojiList, (emoji) => {
				return (
					<div class='emoji_picker__item'>
						<div
							vOn:click={(_) => this.emojiPick(emoji)}
							v-tooltip={':' + emoji + ':'}
							class={'emoji emoji__preview emoji__' + emoji}
						/>
					</div>
				)
			})

			return (
				<div class='emoji_picker' v-clickaway={this.hideEmoji}>
					{$items}
				</div>
			)
		},

		emojiPick(code) {
			insertEmoji(code)(this.editor.state, this.editor.dispatch)
			this.hideEmoji()
			this.editor.focus()
		},

		clearMentions() {
			this.isMentioning = false
			this.filteredActions = []
			this.mentionOffset = 0
		},

		applySuggestion(action) {
			this.clearMentions()
			if (action.confirm) {
				this.editor.setText(action.text + ', hit enter to process...', true)
				// move cursor to end
				this.editor.setSelection(this.editor.getLength(), 0)
				this.editor.formatText(0, this.editor.getLength(), 'color', '#0000ff')
				this.editor.focus()

				return
			}

			if (action.action === 'mention') {
				insertMention(action.text, action.agent_id, 'mention')(this.editor.state, this.editor.dispatch)
				this.mentionQuery = ''
				this.currentSelectedIndex = 0
				this.editor.focus()
				return
			}

			setTimeout(() => this.editor.setText(''))
			//	this.$emit('action', action)
		},

		renderSuggestion() {
			let $help = (
				<div class='suggestion__item__help'>
					<Icon name='corner-down-left' size='16' /> to apply
				</div>
			)
			let $actions = lo.map(this.filteredActions, (action, i) => {
				let active = i === this.currentSelectedIndex
				let cls = 'suggestion__item'
				if (active) cls += ' suggestion__item__active'

				let $content = null
				if (action.action === 'mention') {
					let agent = store.matchAgent()[action.agent_id]
					if (action.agent_id) {
						$content = (
							<div class='suggestion__item__text'>
								<Avatar online agent={agent} size='sm' />
								<span class='ml-2'>{sb.getAgentDisplayName(agent)}</span>
							</div>
						)
						if (this.task_description) {
							$content = (
								<div class='suggestion__item__text'>
									<Avatar online agent={agent} size='sm' />
									<div class='ml-3'>
										<div class='text__sm'>{sb.getAgentDisplayName(agent)}</div>
										<div class='text__sm text__muted'>Agent</div>
									</div>
								</div>
							)
						}
					}
				}
				return (
					<div class={cls} ref={`item${i}`} vOn:click={(_) => this.applySuggestion(action)}>
						{$content} {$help}
					</div>
				)
			})

			let cls = 'suggestion'
			if (!this.isMentioning) cls += ' d-none'
			let style = ''
			if (this.task_description)
				style = `top: ${this.$refs.wrapper ? this.$refs.wrapper.clientHeight : 0}px; bottom: unset;`
			return (
				<div class={cls} v-clickaway={this.hideSuggestion} style={this.suggestStyle}>
					<div>{$actions}</div>
				</div>
			)
		},

		onSubmit() {
			this.$emit('submit')
		},

		uploadClick() {
			this.$refs.file_input.click()
		},

		async uploadLocalFiles(e) {
			let message = lo.cloneDeep(this.initMessage)

			let msg_files = message.attachments || []
			let files = e.target.files || e.clipboardData.files
			let newFiles = []
			for (let i = 0; i < files.length; i++) {
				let file = files[i]
				let id = sb.randomString(20)
				let tempUrl = await sb.getBlobUrlFromFile(file)
				newFiles.push({
					type: 'file',
					mimetype: file.type,
					url: tempUrl,
					size: file.size,
					name: file.name,
					_loading: true,
					_local_id: id,
					_file: file,
				})
			}

			message.attachments = [...msg_files, ...newFiles]
			this.$emit('change', message)

			await flow.map(newFiles, 5, async (file) => {
				let res = await store.uploadLocalFile(file._file)
				let attachmentIndex = lo.findIndex(message.attachments, (cFile) => cFile._local_id === file._local_id)
				if (res.error) {
					if (attachmentIndex > -1) lo.set(message, `attachments.${attachmentIndex}._error`, res.error)
				} else {
					if (attachmentIndex > -1) {
						let mimetype = file.mimetype
						if ((file.name || '').endsWith('heic') || (file.name || '').endsWith('heif')) {
							mimetype = res.type
						}
						lo.set(message, `attachments.${attachmentIndex}`, {
							type: 'file',
							mimetype: mimetype,
							url: res.url,
							size: file.size,
							name: file.name,
							_local_id: file._local_id,
						})
					}
				}
			})

			let error = ''
			lo.each(message.attachments, (file) => {
				if (file._error) {
					error = file._error
					return false // break
				}
			})

			if (error) this.$showError(this.$t(error))

			message.attachments = lo.filter(message.attachments, (file) => !file._error)
			message = lo.cloneDeep(message)
			this.editor && this.editor.focus()

			this.$emit('change', message)
		},

		renderActions() {
			let $attachmentBtn = null
			if (this.useUpLoadFile) {
				$attachmentBtn = (
					<div
						class='d-flex align-items-center mr-3'
						v-tooltip={this.$t('title_attachment')}
						style='cursor: pointer;'
						vOn:click_stop={this.uploadClick}
					>
						<Icon name='paperclip' class='' size='20' stroke-width='2' />
						<input
							type='file'
							style='display:none;'
							multiple
							vOn:change={this.uploadLocalFiles}
							ref='file_input'
							accept={this.acceptAttachment}
						/>
					</div>
				)
			}

			return (
				<div class='message_editor__actions align-items-center'>
					{$attachmentBtn}
					{this.renderEmojiPopup()}
					{this.useEmoji && (
						<div
							class='d-flex align-items-center mr-3'
							style='cursor: pointer;'
							vOn:click_stop={this.toggleEmoji}
							v-tooltip={this.$t('choose_emoji')}
						>
							<Icon size='20' name='mood-smile' />
						</div>
					)}
					{this.useSubmitButton && (
						<button
							type='button'
							class='btn btn__primary align-items-center'
							style='display: inline-flex;'
							vOn:click={this.onSubmit}
						>
							{this.$t('comment')}
						</button>
					)}
				</div>
			)
		},
		removeFile(file) {
			let message = lo.cloneDeep(this.initMessage)
			let attachments = message.attachments || []
			if (file._local_id) {
				attachments = lo.filter(attachments, (att) => att._local_id !== file._local_id)
			} else {
				// remove using url
				attachments = lo.filter(attachments, (att) => att.url !== file.url)
			}

			message.attachments = attachments
			this.$emit('change', message)
			this.editor.focus()
		},

		renderFiles() {
			// find out all file attachment
			let files = lo.filter(
				this.initMessage.attachments,
				(att) => att.type === 'file' && !ImageTypes.includes(att.mimetype),
			)
			if (!lo.size(files)) return null

			let $files = lo.map(files, (file) => {
				return (
					<div class='message_editor__attachment'>
						<div class='message_editor__attachment_file'>
							<Icon name='file-text' stroke-width='1' class='message_editor__attachment_file_logo' size='2x' />
							<div class='d-flex flex-column text-truncate ml-3'>
								<div class='message_editor__attachment_file_name text-truncate'>{file.name}</div>
								<div class='message_editor__attachment_file_size text__muted'>{sb.humanFileSize(file.size)}</div>
							</div>
							<Icon
								name='x'
								v-tooltip={this.$t('remove')}
								size='18'
								stroke-width='2'
								class='message_editor__remove_file'
								vOn:click={(_) => this.removeFile(file)}
							/>
						</div>
					</div>
				)
			})

			if (lo.size(files) === 0) return null
			return <div class='message_editor__attachments__file'>{$files}</div>
		},

		renderImages() {
			// find out all file attachment
			let files = lo
				.filter(this.initMessage.attachments, (att) => att.type === 'file' && ImageTypes.includes(att.mimetype))
				.filter((f) => f.url)

			// find all photo file first
			let $imgs = lo.map(files, (file) => {
				return (
					<div class='message_editor__attachment'>
						<img2 clickable src={file.url} class='message_editor__attachment_image' style='width: 60px' />
						{!file._loading && (
							<Icon
								name='x'
								v-tooltip={this.$t('remove')}
								size='18'
								stroke-width='2'
								class='message_editor__attachment_x'
								vOn:click={(_) => this.removeFile(file)}
							/>
						)}
						{file._loading && (
							<div
								style='position: absolute; z-index: 5; inset: 0; background-color: rgba(255, 255, 255, 0.6)'
								class='d-flex align-items-center justify-content-center'
							>
								<Spinner mode='blue' size='16' />
							</div>
						)}
					</div>
				)
			})

			if (lo.size(files) === 0) return null
			return <div class='message_editor__attachments__image'>{$imgs}</div>
		},
	},

	render() {
		let editor_style = {'--editor-height': `60px`}
		return (
			<div class='comment_editor_wrapper' ref='wrapper'>
				{this.renderSuggestion()}
				<div class='comment_prose_editor' ref={'editor'} style={editor_style}></div>
				<div class='d-flex'>
					<div class='flex__1'></div>
					<div style='padding: 10px 0px;'>{this.renderActions()}</div>
				</div>
				{this.renderImages()}
				{this.renderFiles()}
			</div>
		)
	},
}

function placeholderPlugin(text) {
	return new Plugin({
		props: {
			decorations(state) {
				let doc = state.doc
				if (doc.childCount == 1 && doc.firstChild.isTextblock && doc.firstChild.content.size == 0)
					return DecorationSet.create(doc, [Decoration.widget(1, document.createTextNode(text))])
			},
		},
	})
}

function findStartMentionPos(doc, pos) {
	const isMention = (char) => char === '@'
	let start = pos
	while (start >= 0) {
		if (isMention(doc.textBetween(start - 1, start))) {
			start--
			break
		} else start--
	}
	return start
}

function insertEmoji(code) {
	let emojiType = mySchema.nodes.emoji
	return function (state, dispatch) {
		let {$from} = state.selection,
			index = $from.index()
		if (!$from.parent.canReplaceWith(index, index, emojiType)) return false
		if (dispatch) dispatch(state.tr.replaceSelectionWith(emojiType.create({code})))
		return true
	}
}

function insertMention(name, id, type) {
	let mentionType = mySchema.nodes.mention
	return function (state, dispatch) {
		let {$from, from, to} = state.selection,
			index = $from.index()
		if (!$from.parent.canReplaceWith(index, index, mentionType)) return false
		const startMention = findStartMentionPos(state.doc, from)
		let tr = state.tr
		tr.replaceWith(startMention, to, mentionType.create({name, id, type}))
		tr.insertText('\u00A0', startMention + 1)
		tr = tr.setSelection(TextSelection.create(tr.doc, startMention + 2))
		if (dispatch) dispatch(tr)
		return true
	}
}
