const sb = require('@sb/util')

import Table from '../commons/object-table.js'

export default {
	name: 'manage-msg-temlate-modal',
	props: ['open'],
	data() {
		return {
			keyword: '',
			tab: '',
		}
	},

	created() {
		this.runId = sb.randomString(4) + Date.now()
	},

	watch: {
		open() {
			if (this.open) {
				this.keyword = ''
				this.tab = ''
			}
		},

		tab() {
			this.loadData()
		},

		keyword() {
			this.loadData()
		},
	},

	methods: {
		closeModal() {
			this.$emit('close')
		},

		loadData(options) {
			let $table = this.$refs.table
			if ($table && typeof $table.ApplyFilter === 'function') {
				let query = this.buildQuery()
				$table.ApplyFilter(query, options)
			}
		},

		buildQuery() {
			let req = {run_id: this.runId}
			if (this.tab) {
				if (this.tab === 'message_template') {
					req.is_email = 'false'
				} else if (this.tab === 'email_template') {
					req.is_email = 'true'
				}
			}
			if (lo.trim(this.keyword)) {
				req.keyword = this.keyword
			}
			return req
		},

		renderMsgTemplate(msgtmpl) {
			let $img = null
			let attachments = lo.get(msgtmpl, 'message.attachments', [])
			let images = lo.filter(attachments, (att) => (att.mimetype || '').startsWith('image'))
			let files = lo.filter(attachments, (att) => !(att.mimetype || '').startsWith('image') && att.type === 'file')
			let generics = lo.filter(attachments, (att) => att.type === 'generic')
			let eles = []
			lo.each(generics, (gen) => {
				lo.each(gen.elements, (el) => eles.push(el))
			})

			if (lo.size(files) && !lo.size(images)) {
				$img = (
					<div class='text__muted'>
						<Icon name='paperclip' size='15' />{' '}
						<em>
							{lo.size(files)} {this.$t('title_attachment').toLowerCase()}
						</em>
					</div>
				)
			}
			if (lo.size(images) && !lo.size(files)) {
				$img = (
					<div class='d-flex align-items-center'>
						<div class='message_template_modal_box_item_img'>
							<img2 src={lo.get(images, '0.url')} style='width: 40px; height: 40px' clickable />
						</div>
						{lo.size(images) > 1 && <span class='text__muted ml-2'>+ {lo.size(images) - 1}</span>}
					</div>
				)
			}
			if (lo.size(images) && lo.size(files)) {
				$img = (
					<div class='d-flex align-items-center'>
						<div class='message_template_modal_box_item_img'>
							<img2 src={lo.get(images, '0.url')} style='width: 40px; height: 40px' clickable />
						</div>
						<div class='text__muted ml-2'>
							{lo.size(images) > 1 && (
								<span class='text__muted ml-2'>
									+ {lo.size(images) - 1} {this.$t('image').toLowerCase()}
								</span>
							)}{' '}
							{this.$t('and')} {lo.size(files)} {this.$t('title_attachment').toLowerCase()}
						</div>
					</div>
				)
			}
			if (lo.size(generics)) {
				$img = (
					<div class='text__muted'>
						<Icon style='position: relative; top: -1px' name='layout-columns' size='15' stroke-width='2' />{' '}
						<em>
							{lo.size(eles)} {this.$t('attach_products').toLowerCase()}
						</em>
					</div>
				)
			}

			let subject = lo.get(msgtmpl, 'message.fields', [])
			subject = lo.find(subject, (field) => field.key === 'subject') || {}
			subject = subject.value || '""'
			subject = sb.parseJSON(subject)

			let $creator = null
			if (msgtmpl.creator !== lo.get(store.me(), 'id')) {
				let creator = lo.get(store.matchAgent(), `${msgtmpl.creator}.fullname`)
				if (!creator) creator = this.$t('agent_was_deleted')
				$creator = (
					<div class='text__muted mt-2' style='font-size: 12px'>
						{this.$t('shared_by')} {creator}
					</div>
				)
			}

			let text = lo.get(msgtmpl, 'message.text')
			let isTemplate = lo.get(msgtmpl, 'message.is_template')
			let $text = (
				<div class='text__truncate' style='white-space: pre-wrap'>
					{isTemplate ? sb.lexicalToPlainText(text, {is_show_dynamic_field_token: true}) : text}
				</div>
			)
			if (lo.get(msgtmpl, 'message.format') === 'delta') {
				let delta = lo.get(msgtmpl, 'message.quill_delta')
				delta = sb.parseJSON(delta) || {}
				delta = sb.deltaToPlainText(delta)
				$text = (
					<div class='text__truncate' style='white-space: pre-wrap'>
						{delta}
					</div>
				)
			}
			if (subject) $text = <div class='text__truncate'>{subject}</div>
			return (
				<div class='message_template_modal_box_item w_100'>
					<div style='overflow: hidden; flex: 1;'>
						<div class='d-flex mb-2 align-items-center'>
							<div class='d-flex flex__1 flex-wrap' style='overflow: hidden'>
								{lo.map(msgtmpl.keys, (key) => (
									<div class='message_template_modal_box_item_key'>/{key}</div>
								))}
							</div>
							<div class='message_template_modal_box_item_actions'>
								<div class='link' vOn:click={() => this.$emit('edit', lo.cloneDeep(msgtmpl))}>
									{this.$t('edit')}
								</div>
								<div class='dot dot--sm dot__secondary mr-2 ml-2' style='margin-top: 0' />
								<Icon
									name='trash'
									size='14'
									class='link link__secondary'
									vOn:click={(_) => this.onDeleteMessageTemplate(msgtmpl.id)}
								/>
							</div>
						</div>
						{$text}
						{$img}
						{$creator}
					</div>
				</div>
			)
		},

		renderTabs() {
			let $create = (
				<Dropdown
					mode='link'
					placeholder={`${this.$t('create_new')}`}
					class='ml-auto'
					extra_cls={'link'}
					dropdown_width={250}
					right
					vOn:select={this.onSelectCreateMessageTemplate}
					items={[
						{id: 'subiz', label: 'Mẫu tin nhắn subiz, facebook, zalo'},
						{id: 'email', label: 'Mẫu tin nhắn email'},
					]}
				/>
			)
			return (
				<div class='tab' style='width: 100%; margin-bottom: 0; padding: 0 10px;'>
					<div class={{tab__item: true, tab__item__active: !this.tab}} vOn:click={() => (this.tab = '')}>
						<div class='text__semibold'>{this.$t('all_template')}</div>
					</div>

					<div
						class={{tab__item: true, tab__item__active: this.tab === 'message_template'}}
						vOn:click={() => (this.tab = 'message_template')}
					>
						<div class='text__semibold'>{this.$t('message_template')}</div>
					</div>
					<div
						class={{tab__item: true, tab__item__active: this.tab === 'email_template'}}
						vOn:click={() => (this.tab = 'email_template')}
					>
						<div class='text__semibold'>{this.$t('email_template')}</div>
					</div>

					<div style='flex: 1'></div>
					<div class='d-flex align-items-center'>{$create}</div>
				</div>
			)
		},

		onSelectCreateMessageTemplate(item) {
			let msgtmpl = {
				id: 'new',
				channel_type: item.id,
				is_public: true,
			}
			this.$emit('edit', msgtmpl)
		},

		async onDeleteMessageTemplate(id) {
			let cf = await this.$confirm({
				title: this.$t('delete_message_template'),
				description: this.$t('delete_message_template_desc'),
				style: 'danger',
				ok: this.$t('delete'),
				cancel: this.$t('cancel'),
			})
			if (!cf) return
			let {error} = await store.removeMessageTemplate(id)
			if (error) {
				this.$showError(error)
			}
			this.$forceUpdate()
		},
	},

	render() {
		return (
			<Modal show={this.open} vOn:bg={this.closeModal}>
				<div class='modal__background'>
					<div class='modal_content' style='padding: 20px; width: 720px'>
						<div class='d-flex mb-4'>
							<div>
								<div style='font-size: 18px;' class='text__semibold'>
									{this.$t('message_template')}
								</div>
								<small style='font-size: 14px;' class='text__muted'>
									{this.$t('message_template_des')}
								</small>
							</div>
							<Icon name='x' class='x-icon ml-auto' size='24' vOn:click={this.closeModal} />
						</div>

						<div class='input_search mb-4' style='width: 300px'>
							<Icon name='search' class='input_search__icon_search' size='16' />
							<input
								style='	min-height: 30px;padding-top: 0;padding-bottom: 0'
								class='form-control input_search__input'
								placeholder={this.$t('find_by_keyword')}
								ref='search_input'
								vModel={this.keyword}
							/>
							{this.keyword && (
								<Icon name='x' class='input_search__icon_close' vOn:click={(_) => (this.keyword = '')} />
							)}
						</div>
						<div class='message_template_modal_box'>
							<div class='message_template_modal_box_head'>{this.renderTabs()}</div>
							<div class='message_template_modal_box_content' style='overflow-y: hidden'>
								<Table
									ref='table'
									object_type='template'
									init_query={{run_id: this.runId}}
									columns={[
										{
											key: 'name',
											flex: 1,
											render: this.renderMsgTemplate,
										},
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	},
}
