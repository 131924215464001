const sb = require('@sb/util')
import MessageEventActions from './message_event_actions.js'

export default {
	name: 'file-attachments',
	props: ['ev'],
	data() {
		return {
			focusedIdx: -1,
		}
	},

	created() {
		this.$root.$on('focusEvent', this.justFocus)
	},

	beforeDestroy() {
		this.$root.$off('focusEvent', this.justFocus)
	},

	methods: {
		justFocus(ev) {
			if (ev.id !== this.ev.id) return
			let attachmentIndex = sb.getMsgField(ev, 'attachment_index')
			let attachments = lo.get(ev, 'data.message.attachments') || []
			let att = attachments[attachmentIndex]
			if (!att) return // quote text message
			this.focusedIdx = attachmentIndex
			if (this.focusTimeout) clearTimeout(this.focusTimeout)
			this.focusTimeout = setTimeout(() => {
				this.focusedIdx = -1
			}, 3000)
		},

		downloadFile(file) {
			window.open(sb.replaceFileUrl(file.url), '_blank').focus()
			//sb.downloadURI(sb.replaceFileUrl(file.url), file.name)
		},

		onAction(actionType, data, idx) {
			if (actionType === 'reply') return this.onReplyFile(idx)
		},

		onReplyFile(idx) {
			let ev = lo.cloneDeep(this.ev)
			lo.set(ev, 'data.message.text', '')
			sb.setMsgField(ev, 'attachment_index', idx)
			this.$emit('reply', ev)
		},
	},

	render() {
		var byType = lo.get(this.ev, 'by.type', 'agent')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var files = []
		lo.each(attachments, (att, idx) => {
			if (!att || !att.url) return
			if (att.type !== 'file') return
			if (
				!lo.includes(att.mimetype, 'video') &&
				!lo.includes(att.mimetype, 'audio') &&
				!lo.includes(att.mimetype, 'image')
			) {
				files.push({...att, _true_idx: idx})
			}
		})

		if (files.length === 0) return null

		var $files = lo.map(files, (file, index) => {
			return (
				<div
					class={{
						message_file: true,
						message_file__user: byType === 'user',
						message_file__agent: byType !== 'user',
						focused: file._true_idx === this.focusedIdx,
					}}
				>
					<div class='message_file__link' vOn:click={(_) => this.downloadFile(file)} target='_blank'>
						<Icon name='file' stroke-width='2' class='message_file__download' size='24' />
						<div style='overflow:hidden'>
							<div class='message_file__filename' title={file.name}>
								{file.name}
							</div>
							<div class='message_file__size'>
								{sb.humanFileSize(file.size)}{' '}
								<a
									vOn:click_stop={() => false}
									href={file.url + '?&cd=' + encodeURIComponent('attachment; filename=' + file.name)}
									target='_blank'
								>
									{this.$t('save')}
								</a>
							</div>
						</div>
					</div>
					<MessageEventActions ev={this.ev} vOn:action={(type, data) => this.onAction(type, data, file._true_idx)} />
				</div>
			)
		})

		return (
			<div class={`message_container message_container__${byType}`}>
				<div class='d-flex flex-column' style='max-width: 100%'>
					{$files}
				</div>
			</div>
		)
	},
}
