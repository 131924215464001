const sb = require('@sb/util')

var api = require('./api.js')
const flow = require('@subiz/flow')
const config = require('@sb/config')
var Realtime = require('@subiz/wsclient/realtime.js')

import SubizLog from '../log/main.js'
import NewAccountStore from './account_store.js'
import NewConvoStore from './convo_store.js'
import NewLiveStore from './live_store.js'
// import NewRecentStore from './recent_store.js'
import NewOrderStore from './order_store.js'
import NewTaskStore from './task_store.js'
import NewCallEntryStore from './outbound_call_entry_store.js'
import NewTicketStore from './ticket_store.js'
import {sleep} from '@sb/util/time.js'

// run log
const DEBUG_EXPIRED = 2 * 24 * 3600_000 // 2 days
let sbz_log = SubizLog({account_id: 'unlogin', agent_id: 'unlogin'})
if (lo.get(window, 'location.href', '').indexOf('__debug') != -1) sbz_log.startLog()

function Store() {
	let dead = false
	let pubsub = new sb.Pubsub()
	this.pubsub = pubsub
	let alreadyInit = false
	let realtime
	let hasOnboardingRedirected = false
	this.me = () => ({})

	this.getTokenExpiredState = api.getTokenExpiredState
	this.init = async (cf) => {
		if (alreadyInit) return
		console.time('init store')
		// registering service worker
		if (cf && cf.cred) await api.setCred(cf.cred)
		if (cf && cf.account_id) api.setAccountId(cf.account_id)
		let cred = api.getCred() || {}
		if (!cred.access_token) return {error: 'access_deny'}

		await api.fetchAgentProfile()
		let agprofile = api.getAgentProfile()
		let accid = api.getAccountId()

		if (!accid) {
			const out = await api.list_agent_accounts(agprofile.id)
			let accounts = lo.get(out, 'body.accounts', [])
			accounts = lo.filter(
				accounts,
				(account) => !account.login_locked && account.state === 'activated' && account.agent_state == 'active',
			)
			accounts.sort((a, b) => a.created - b.created)
			alreadyInit = false

			if (lo.size(accounts) >= 0) {
				let acc = lo.find(accounts, (acc) => acc.id == agprofile.default_account_id) || accounts[0]
				accid = lo.get(acc, 'id', '')
				api.setAccountId(accid)
			}
		}

		// make sure db_version in localstorage is alway correct
		if (!window.myLocalStorage.getItem('db_version')) window.myLocalStorage.setItem('db_version', config.db_version)
		if (window.myLocalStorage.getItem('db_version') !== config.db_version) window.myLocalStorage.clear()
		window.myLocalStorage.setItem('db_version', config.db_version)
		dead = false
		// check current token is valid?

		realtime = new Realtime(config.RealtimeURL, {getAccessToken: api.makeAccessToken}, undefined, api.getAccountId())

		let rtsdb = {}
		realtime.onEvent((ev) => {
			rtsdb[ev.type] = rtsdb[ev.type] || 0
			rtsdb[ev.type]++
			this.pubsub.publish('rt', ev)
		})
		this.showRealtimeStatistic = () => rtsdb

		this.accStore = NewAccountStore(realtime, this.pubsub, this)
		// this.recentStore = NewRecentStore(this.convoStore, realtime, pubsub)
		this.orderStore = NewOrderStore(realtime, this.pubsub)
		this.ticketStore = NewTicketStore(realtime, this.pubsub)
		this.convoStore = NewConvoStore(realtime, this.pubsub, this.ticketStore)
		this.taskStore = NewTaskStore(realtime, this.pubsub)
		this.callEntryStore = NewCallEntryStore(realtime, this.pubsub)
		this.liveStore = NewLiveStore(this.convoStore, realtime, this.pubsub)

		// run log
		const DEBUG_EXPIRED = 2 * 24 * 3600_000 // 2 days
		sbz_log.reinit({account_id: api.getAccountId(), agent_id: cred.agent_id})
		this.accStore.onAccount2((data) => {
			if (lo.get(data, 'object_type') === 'agent') {
				let agent = lo.get(data, 'data') || {}
				//consoleNew.log('agentttt', agent)
				let debugCollected =
					lo.get(agent, 'dashboard_setting.debug_log_collected', 0) ||
					lo.get(window, 'location.href', '').indexOf('__debug') != -1
				if (debugCollected && Date.now() - debugCollected < DEBUG_EXPIRED) {
					sbz_log.startLog()
					api.startLog()
				} else {
					sbz_log.stopLog()
				}
			}
		})

		Object.assign(this, this.convoStore)
		Object.assign(this, this.accStore)
		Object.assign(this, this.liveStore)
		// Object.assign(this, this.recentStore)
		Object.assign(this, this.orderStore)
		Object.assign(this, this.ticketStore)
		Object.assign(this, this.taskStore)
		Object.assign(this, this.callEntryStore)

		this.accStore.fetchIntegrations()
		this.convoStore.fetchRecentCalls()
		let mustDone = [
			this.accStore.fetchSubscription(),
			this.accStore.fetchAccount(),
			this.accStore.fetchIntegrations(),
			//this.accStore.fetchSites(),
			this.accStore.fetchSetupFeatures(),
		]
		if (lo.size(this.accStore.matchAgent()) > 0) {
			this.accStore.fetchAgents()
		} else {
			mustDone.push(this.accStore.fetchAgents())
		}
		await Promise.all(mustDone)

		let acc = this.accStore.me().account
		if (acc.login_locked || !acc.id) {
			// dont allow to use with locked account
			api.setAccountId('')
			if (window.location.pathname != '/agent/accounts' && !window.location.pathname.startsWith('/agent/accounts'))
				window.location.href = '/agent/accounts'
		}
		// refuse to load
		this.accStore.fetchCredit()
		setTimeout(() => this.accStore.fetchTags(), 5000)
		this.accStore.fetchUserAttributes()
		this.accStore.fetchUserLabels()
		alreadyInit = true
		console.timeEnd('init store')
	}

	this.markOnboardingRedirected = () => {
		hasOnboardingRedirected = true
	}

	this.shouldShowOnboarding2 = () => {
		if (hasOnboardingRedirected) return false
		//return true
		let plan = lo.get(this.accStore.matchSubscription(), 'plan')
		if (plan && plan !== 'trial' && plan !== 'free') return false

		let scopes = lo.get(this.accStore.me(), 'scopes') || []
		if (!lo.includes(scopes, 'account_setting') && !lo.includes(scopes, 'account_manage') && !lo.includes('owner'))
			return false

		// if alreay check all, dont show
		let progress = this.accStore.getFeaturesProgess()
		if (progress >= 100) return false
		return true
	}

	this.shouldShowOnboarding = () => {
		// temp remove later
		return false
		let plan = lo.get(this.accStore.matchSubscription(), 'plan')
		if (plan && plan !== 'trial' && plan !== 'free') return false
		let sites = this.accStore.matchSite()
		if (lo.size(sites)) return false
		let fbIntes = lo.filter(this.accStore.matchIntegration(), (inte) => inte.connector_type === 'facebook')
		let instaIntes = lo.filter(this.accStore.matchIntegration(), (inte) => inte.connector_type === 'instagram')
		let zaloIntes = lo.filter(this.accStore.matchIntegration(), (inte) => inte.connector_type === 'zalo')
		let callIntes = lo.filter(this.accStore.matchIntegration(), (inte) => inte.connector_type === 'call')
		if (lo.size(fbIntes) || lo.size(instaIntes) || lo.size(zaloIntes) || lo.size(callIntes)) {
			return false
		}
		return true
	}

	this.clarityScript = null
	this.addClarityTracingScript = async () => {
		if (process.env.ENV !== 'prod') return
		if (this.clarityScript) return
		let script = document.createElement('script')
		script.type = 'text/javascript'
		script.textContent = `
        (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "nr1yb4vb99");
    `
		if (document.head) {
			this.clarityScript = script
			console.log('addClarityTracingScript append clarity script to head')
			document.head.appendChild(script)
		}

		// add account_id and agent_id in clarity
		setTimeout(() => {
			if (window.clarity) {
				let me = this.accStore.me()
				let agentProfile = this.matchAgentProfile()
				window.clarity('set', 'sbz_acc_id', lo.get(me, 'account.id'))
				window.clarity('set', 'sbz_agent_id', lo.get(me, 'id'))
				window.clarity('set', 'sbz_agent_profile_email', lo.get(me, 'email') || lo.get(agentProfile, 'email'))
			}
		}, 1000)
	}

	let destroy = async () => {
		if (dead) return
		dead = true

		// clear database
		window.myLocalStorage.clear()

		// this.recentStore && this.recentStore.destroy()
		this.liveStore && this.liveStore.destroy()
		this.accStore && this.accStore.destroy()
		this.orderStore && this.orderStore.destroy()
		this.convoStore && this.convoStore.destroy()
		this.ticketStore && this.ticketStore.destroy()
		this.taskStore && this.taskStore.destroy()
		realtime && realtime.stop()
	}

	this.getCred = api.getCred
	this.login = async (us, pw, redirect) => {
		await destroy()
		let {cred, error, code} = await api.login(us, pw)
		if (error) return {error, code}

		const out = await api.list_agent_accounts(cred.agent_id)
		if (out.error) return out

		let accounts = lo.get(out.body, 'accounts', [])
		accounts = lo.filter(
			accounts,
			(account) => !account.login_locked && account.state === 'activated' && account.agent_state == 'active',
		)
		accounts.sort((a, b) => a.created - b.created)
		alreadyInit = false
		if (redirect) {
			let agprofile = api.getAgentProfile()
			let acc = lo.find(accounts, (acc) => acc.id == agprofile.default_account_id) || accounts[0]
			const accid = lo.get(acc, 'id', '')
			if (accid) await this.init({account_id: accid})
			else await this.init()
		} else {
			await this.init()
		}
		return {cred}
	}

	api.onLogout = () => this.logout()

	// only to be called by main layout
	this.logout = async () => {
		this.pubsub.publish('logout')
		await api.logout()
		await destroy()
	}
	;(async () => {
		while (true) {
			try {
				if (!dead && this.fetchPresences) await this.fetchPresences()
			} catch (e) {}
			await flow.sleep(120_000)
		}
	})()

	// sub
	this.now = (_) => sb.now()
	this.onNumberInfo = (o, cb) => this.pubsub.on2(o, 'number_info', cb)
	this.onLogout = (o, cb) => this.pubsub.on2(o, 'logout', cb)
	this.onRealtime = (o, cb) => this.pubsub.on2(o, 'rt', cb)
	this.onStandardObject = (o, cb) => this.pubsub.on2(o, 'standard_object', cb)
	this.un = (o) => this.pubsub.un2(o)

	let _banks = []
	this.fetchVnBanks = async () => {
		if (lo.size(_banks) > 0) return
		let out = await api.listBanks()
		if (out.error) return
		_banks = out.body.banks
	}

	this.matchVnBank = (bin) => {
		if (bin == undefined) return _banks
		return lo.find(_banks, (bank) => bank.bin == bin)
	}

	this.importBusinessHours = api.importBusinessHours
	this.register = api.register
	this.makeAccessToken = api.makeAccessToken
	this.confirmation = api.confirmation
	this.fetchWidget = api.fetchWidget

	this.fetchUsedCategory = () => api.list_product_categories()

	this.inviteAgent = api.inviteAgent

	this.browserVisibility = 'visible'
	onVisibilityChange((val) => {
		this.pubsub.publish('document_focus', val)
		this.browserVisibility = val
	})

	this.onDocumentFocus = (o, cb) => this.pubsub.on2(o, 'document_focus', cb)

	this.addGroup = api.addGroup

	this.getUrlUpload = api.getUrlUpload

	this.resetPassword = api.resetPassword
	this.updatePasswordViaToken = api.updatePasswordViaToken
	this.updatePassword = api.updatePassword
	this.forgotPassword = api.forgotPassword
	this.updateAgentInvitation = api.updateAgentInvitation

	this.getTagReport = api.getTagReport
	this.reportConvoReplied = api.reportConvoReplied
	this.reportAvailability = api.reportAvailability

	this.searchLocations = api.searchLocations

	this.exportInvoice = api.exportInvoice
	this.genQrCode = api.genQrCode
	this.exportInvoiceHtml = api.exportInvoiceHtml
	this.calculateInvoice = api.calculateInvoice
	this.upgradeSubscription = api.upgrade_subscription
	this.upgradeSub = (accid, sub) => api.upgrade_sub(accid, sub)
	this.updateSub = (sub) => api.update_sub(sub)
	this.paidInvoice = (invoice) => api.subscriptionPay(invoice)
	this.downgradeSubscription = api.update_subscription
	this.listBotConversations = api.listBotConversations
	this.testSendHttp = api.test_bot_action
	this.listFacebookPosts = api.list_facebook_posts
	this.listCountryCodes = api.listCountryCodes
	this.onDocument = (vue, ev, handler) => {
		document.body.addEventListener(ev, handler)
		vue.$once('hook:beforeDestroy', (_) => document.body.removeEventListener(ev, handler))
	}
	this.getUserReport = api.getUserReport
	this.getConvoReport = api.getConvoReport
	this.getConvoReport2 = api.getConvoReport2
	this.getConvoIds = api.getConvoIds
	this.getAgentReport = api.getAgentReport
	this.getListTouchpoints = api.getListTouchpoints
	this.getOrderReport = api.get_order_report
	this.getCallReport = api.getCallReport
	this.getCallIds = api.getCallIds
	this.getReport = api.getReport
	this.widgetCreateConvo = api.widget_create_convo
	this.widgetFetchUser = api.widget_fetch_user
	this.widgetSendConvoEvent = api.widget_send_convo_event
	this.fetchAiResponseSource = api.list_ai_response_source

	let callInfoM = {}
	this.matchNumberInfo = (number) => {
		if (!number) return {info: {}}
		let number_format = ''
		for (let i = 0; i < number.length; i++) {
			if (number[i] >= '0' && number[i] <= '9') number_format += number[i]
		}
		if (callInfoM[number_format]) return callInfoM[number_format]
		callInfoM[number_format] = {state: 'loading', info: {}}
		setTimeout(async () => {
			let out = await this.fetchCallInfo(number_format)
			callInfoM[number_format] = {state: 'ready', info: out}
			this.pubsub.publish('number_info', {number: number_format, state: 'ready', info: out})
		})
		return callInfoM[number_format]
	}

	this.collect = api.collect

	this.error = (message, line) => {
		// 24-01-11 04:07:05 app ERROR api-8948967b6-qtpfx subiz /app/vendor/github.com/subiz/log/error.go:271 {"id":141534190473829746,"class":500,"code":"locked_account,internal","number":"8f2b97e9","fields":{"ip":"\"171.224.84.93\"","no_report":"true","user_agent":"\"Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Mobile Safari/537.36\""}}
		let ts = displayUTCLogTime(new Date())
		let accid = this.me().account_id
		let msg = JSON.stringify({
			id: Date.now(),
			class: 400,
			code: 'frontend_error',
			number: line
				.split('')
				.map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
				.join(''),
			fields: {
				message: JSON.stringify(message),
				agent_id: JSON.stringify(this.me().id),
				url: JSON.stringify(location.href),
			},
		})

		api.collectLog(`${ts} app ERROR dashboard ${accid} ${line} ${msg}`)
	}

	this.getRangeVersionSupported = async (platform) => {
		let out = await api.get_range_version_supported(platform)
		if (out.error) return undefined
		return out.body
	}

	// see account_store.lookupCallUser
	this.fetchCallInfo = async (number) => {
		number = number || ''
		if (number.startsWith('ph') || number.startsWith('piph')) {
			let us = number.split('.')
			if (us[1] == this.me().account_id) {
				// device
				if (us[0].startsWith('piph')) us[0] = us[0].substr(2)
				let dev = this.matchPhoneDevice()[us[0]]
				if (!dev) {
					await this.fetchPhoneDevice(true)
					dev = this.matchPhoneDevice()[us[0]]
				}
				if (!dev) return {type: 'agent', fullname: '', number, avatar_url: ''}

				let agid = lo.get(dev, 'bind_to_agents.0')
				let ag = this.matchAgent()[agid]
				if (!ag) return {type: 'agent', fullname: '', number, avatar_url: ''}
				return {
					type: 'agent',
					id: agid,
					fullname: sb.getAgentDisplayName(ag),
					number: ag.extension,
					avatar_url: ag.avatar_url,
				}
			}
		}

		let ags = this.matchAgent()
		if (number.startsWith('ag')) {
			let found = lo.find(ags, (ag) => ag.id == number)
			if (found)
				return {
					fullname: found.fullname,
					is_internal: true,
					avatar_url: found.avatar_url,
					type: 'agent',
					id: found.id,
					number: found.extension || number,
				}

			// other account agent
			let {body: ag, error} = api.get_agent(number)
			if (!ag || error) return {number, fullname: '', avatar_url: '', type: 'user'}

			await this.fetchUser('us' + ag.account_id + '_' + ag.id)
			let user = this.matchUser('us' + ag.account_id + '_' + ag.id)
			let fullname = sb.getUserDisplayName(user)
			let avatar_url = sb.getUserTextAttr(user, 'avatar_url')
			avatar_url = sb.replaceFileUrl(avatar_url)
			return {number, id: user.id, fullname, avatar_url, type: 'user'}
		}

		// our extension
		let found = lo.find(ags, (ag) => ag.state == 'active' && ag.extension == number)
		if (found)
			return {
				fullname: found.fullname,
				is_internal: true,
				avatar_url: found.avatar_url,
				type: 'agent',
				id: found.id,
				number: found.extension || number,
			}

		let phones = this.matchPhoneDevice()
		found = lo.find(phones, (phone) => phone.id == number)
		if (found) {
			let bounds = found.bind_to_agents || []
			let ag = lo.find(ags, (ag) => ag.state == 'active' && bounds.indexOf(ag.id) > -1)
			if (ag)
				return {
					fullname: ag.fullname,
					is_internal: true,
					avatar_url: ag.avatar_url,
					type: 'agent',
					id: ag.id,
					number: ag.extension || number,
				}
			return {number, fullname: '', is_internal: true, avatar_url: '', type: 'agent'}
		}

		if ((number || '').startsWith('0')) number = '84' + number.substr(1)
		let user = await this.fetchUserByProfile('call', 'call', number)
		if (user.error) return {number, fullname: '', avatar_url: '', type: 'user'}
		let fullname = sb.getUserDisplayName(user)
		let avatar_url = sb.getUserTextAttr(user, 'avatar_url')
		avatar_url = sb.replaceFileUrl(avatar_url)
		return {number, id: user.id, fullname, avatar_url, type: 'user'}
	}

	this.checkInviteLink = async (link_id) => {
		let out = await api.check_invite_link(link_id)
		return out.body
	}

	this.changePassword = async (email, otp, newpassword) => {
		let out = await api.update_password(email, otp, newpassword)
		return out.body
	}
	this.register_account = async (data) => {
		let out = await api.register(data)
		return out.body
	}

	// p must contain email
	this.acceptInvitation = async (code, p) => {
		let out = await api.accept_invitation(code, p)
		return out.body
	}

	this.requestOtp = async (email) => {
		let out = await api.request_otp(email)
		return out.body
	}

	this.checkOtp = async (otp, email) => {
		let out = await api.check_otp(otp, email)
		return out.body
	}

	this.checkEmailExist = async (email) => {
		let out = await api.check_email_exist(email)
		return out.body
	}

	this.listAgentAccounts = async () => {
		let me = this.me() || {}
		let agentProfile = this.matchAgentProfile() || {}
		let agid = me.id || agentProfile.id
		let out = await api.list_agent_accounts(agid)
		let agemail = me.email || agentProfile.email || 'empty_agent_email'
		let sbz_log = SubizLog({account_id: 'none', agent_id: agid})
		if (out.error || out.body.error) {
			let cred = {}
			if (window.localStorage && window.localStorage.getItem && typeof window.localStorage.getItem === 'function') {
				cred = window.localStorage.getItem('cred')
				cred = sb.parseJSON(cred)
			}
			sbz_log.postLog(
				'CRED: ' + cred.access_token + ' AGENT_ID: ' + agid + ' AGENT_EMAIL: ' + agemail + ' ' + JSON.stringify(out),
				'EMPTY_ACCOUNTS',
			)
			return out.body
		}

		let accounts = lo.get(out.body, 'accounts', [])
		accounts = lo.filter(accounts, (account) => account.agent_state == 'active')
		if (!lo.size(accounts)) {
			let cred = {}
			if (window.localStorage && window.localStorage.getItem && typeof window.localStorage.getItem === 'function') {
				cred = window.localStorage.getItem('cred')
				cred = sb.parseJSON(cred)
			}
			sbz_log.postLog(
				'CRED: ' + cred.access_token + ' AGENT_ID: ' + agid + ' AGENT_EMAIL: ' + agemail + ' ' + JSON.stringify(out),
				'EMPTY_ACCOUNTS',
			)
		}
		return {accounts}
	}

	this.matchAgentProfile = api.getAgentProfile
}

function onVisibilityChange(cb) {
	if (!window.addEventListener) return // mobile

	var hidden = 'hidden'

	window.addEventListener('focus', onchange)
	window.addEventListener('blur', onchange)

	// Standards:
	if (hidden in document) document.addEventListener('visibilitychange', onchange)
	else if ((hidden = 'mozHidden') in document) document.addEventListener('mozvisibilitychange', onchange)
	else if ((hidden = 'webkitHidden') in document) document.addEventListener('webkitvisibilitychange', onchange)
	else if ((hidden = 'msHidden') in document) document.addEventListener('msvisibilitychange', onchange)
	// IE 9 and lower:
	else if ('onfocusin' in document) document.onfocusin = document.onfocusout = onchange
	// All others:
	else window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange

	function onchange(evt) {
		var v = 'visible'
		var h = 'hidden'
		var evtMap = {focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h}

		evt = evt || window.event
		if (evt.type in evtMap) cb(evtMap[evt.type])
		else cb(this[hidden] ? 'hidden' : 'visible')
	}

	// set the initial state (but only if browser supports the Page Visibility API)
	if (document[hidden] !== undefined) onchange({type: document[hidden] ? 'blur' : 'focus'})
}

let store = new Store()
window.store = store
window.flow = flow
window.lo = lo

function displayUTCLogTime(date) {
	let yyyy = date.getUTCFullYear().toString()
	let yy = yyyy.substr(2, 2)
	let mm = date.getUTCMonth() + 1
	let dd = date.getUTCDate()
	let hh = date.getUTCHours()
	let MM = date.getUTCMinutes()
	let ss = date.getUTCSeconds()

	return `${yy}-${pad(mm)}-${pad(dd)} ${pad(hh)}:${pad(MM)}:${pad(ss)}`
}

function pad(v) {
	if (v < 10) {
		return `0${v}`
	}

	return `${v}`
}

export default store
