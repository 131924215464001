var api = require('./api.js')
var config = require('@sb/config')
const sb = require('@sb/util')

// download file from an url and upload to subiz server
// options to upload file to thumbnail like 50x50
// so you need options.size = 50, options.width and options.height is image widht height
// and offset is offset to crop image
//

async function upload(file, options = {}) {
	const MAX_SIZE = 25 * 1024 * 1024 // 25MB
	if (file.size > MAX_SIZE) return {error: 'file_so_large'}
	if (error) return {error}

	var {body, error} = await api.upload_file(file._file, {
		description: 'image upload\n agent-client: web-app-dashboard',
		name: file.name.replace(/\s+/g, '_'),
		gentext: options.gentext ? 'true' : '',
	})
	if (error) return {error}
	return body.file || {url: ''}
}

const uploadLocalFile = (fileInfo, options) =>
	upload({_file: fileInfo, name: fileInfo.name, size: fileInfo.size, mimetype: fileInfo.type}, options)

async function replaceMessageBase64Images(message) {
	message = lo.cloneDeep(message)
	let delta = sb.parseJSON(message.quill_delta)
	let blobs = getAllMessageBlobPaths(message)

	let error = ''
	if (message.format === 'html') {
		let htmlString = message.text || ''
		// dont need to sanitize, see https://stackoverflow.com/questions/37554623/can-a-new-domparser-parsefromstring-be-safer-than-createelement
		// htmlString = DOMPurify.sanitize(htmlString, {ALLOW_UNKNOWN_PROTOCOLS: true})

		const parser = new DOMParser()
		const dom = parser.parseFromString(htmlString, 'text/html')
		const $imgs = dom.querySelectorAll('img')

		let hasBlob = false
		for (let i = 0; i < lo.size($imgs); i++) {
			let $img = $imgs[i]
			let url = $img.getAttribute('src')
			if (!isBlobUrl(url) && !isDataUrl(url)) continue
			hasBlob = true
			let file
			if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
			if (isDataUrl(url)) file = await sb.dataURLToFile(url)
			let res = await uploadLocalFile(file)
			if (res.error) {
				error = res.error
			}

			$img.setAttribute('src', res.url)
			$img.setAttribute('subizimage', 'true')
		}
		if (hasBlob) {
			lo.set(message, 'text', dom.body.innerHTML)
		}
		console.log('uploaded base64 imgggggg', message.text)
	}

	await flow.map(blobs, 5, async (blob) => {
		let {url, path, type} = blob
		let file
		if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
		if (isDataUrl(url)) file = await sb.dataURLToFile(url)
		let res = await uploadLocalFile(file)
		if (res.error) {
			error = res.error
		}
		let newUrl = res.url
		if (type === 'attachment') {
			lo.set(message, path, newUrl)
		} else if (type === 'quill_delta') {
			lo.set(delta, `ops.${path}`, newUrl)
		}
	})
	if (delta) {
		lo.set(message, 'quill_delta', JSON.stringify(delta))
	}

	return {message, error}
}

function getAllMessageBlobPaths(message) {
	let output = []

	let attachments = lo.get(message, 'attachments')
	lo.each(attachments, (att, attIndex) => {
		if (att.type === 'generic') {
			lo.each(att.elements, (ele, eleIndex) => {
				if (isBlobUrl(ele.image_url) || isDataUrl(ele.image_url)) {
					output.push({
						path: `attachments.${attIndex}.elements.${eleIndex}.image_url`,
						url: ele.image_url,
						type: 'attachment',
					})
				}
			})
		} else {
			if (isBlobUrl(att.url) || isDataUrl(att.url)) {
				output.push({
					path: `attachments.${attIndex}.url`,
					url: att.url,
					type: 'attachment',
				})
			}
		}
	})

	let delta = sb.parseJSON(message.quill_delta) || {ops: []}
	lo.each(delta.ops, (op, idx) => {
		let img = lo.get(op, 'insert.image')
		if (!img) return // continue loop
		if (isBlobUrl(img) || isDataUrl(img)) {
			output.push({
				path: `${idx}.insert.image`,
				url: img,
				type: 'quill_delta',
			})
		}
	})
	return output
}

function isBlobUrl(url = '') {
	return url.startsWith('blob')
}

function isDataUrl(url = '') {
	return url.startsWith('data:')
}

export default {
	upload,
	uploadLocalFile,
	replaceMessageBase64Images,
}
