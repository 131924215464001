var config = require('@sb/config')

var {now, getMs} = require('./time.js')
const accounting = require('accounting')
const datefns = require('date-fns')
const createDOMPurify = require('dompurify')
const IMG_MAX_WIDTH = 500
var {extractText} = require('./extract.js')

const plural = function (str) {
	// fuck english
	if (!str) return ''

	if (str.endsWith('ay') || str.endsWith('ey') || str.endsWith('oy') || str.endsWith('uy') || str.endsWith('iy')) {
		return str + 's'
	}

	// entry -> entries
	if (str.endsWith('y')) {
		return str.substring(0, str.length - 1) + 'ies'
	}

	// analysis → analyses, ellipsis → ellipses
	if (str.endsWith('is')) return str.substring(0, str.length - 2) + 'es'

	if (str.endsWith('wolf')) return str.substring(0, str.length - 4) + 'wolves'
	if (str.endsWith('self')) return str.substring(0, str.length - 4) + 'selves'
	if (str.endsWith('loaf')) return str.substring(0, str.length - 4) + 'loaves'
	if (str.endsWith('thief')) return str.substring(0, str.length - 5) + 'thieves'
	if (str.endsWith('knife')) return str.substring(0, str.length - 5) + 'knives'

	// 		–s, –ss, –sh, –ch, –x, và –z
	// Bus → buses, Fox → foxes
	if (
		str.endsWith('s') ||
		str.endsWith('ss') ||
		str.endsWith('sh') ||
		str.endsWith('ch') ||
		str.endsWith('x') ||
		str.endsWith('z')
	) {
		return str + 'es'
	}
	return str + 's'
}

const snackCase = function (str) {
	if (!str) return ''
	return str.replace(/-/g, '_')
}

const snakeCaseToCapitalCamelCase = function (str) {
	let words = lo.split(str, '_')
	words = lo.map(words, (word) => {
		return word.charAt(0).toUpperCase() + word.slice(1)
	})
	words = lo.join(words, '')
	return words
}

var makeCRCTable = function () {
	var c
	var crcTable = []
	for (var n = 0; n < 256; n++) {
		c = n
		for (var k = 0; k < 8; k++) {
			c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1
		}
		crcTable[n] = c
	}
	return crcTable
}
const CRC32_IEEE_TABLE = makeCRCTable()

var crc32 = function (str) {
	var crc = 0 ^ -1

	for (var i = 0; i < str.length; i++) {
		crc = (crc >>> 8) ^ CRC32_IEEE_TABLE[(crc ^ str.charCodeAt(i)) & 0xff]
	}

	return (crc ^ -1) >>> 0
}

function isTopicIdConvo(topicid = '') {
	if (typeof topicid !== 'string') return false
	return topicid.startsWith('cs')
}

// convoOfEv extracts the ID of the conversation
// that event is sent to
function convoOfEv(ev) {
	if (!ev) return ''
	if (ev._convoid) return ev._convoid
	switch (ev.type) {
		case 'conversation_state_updated':
		case 'conversation_joined':
		case 'conversation_invited':
		case 'conversation_left':
		case 'conversation_tagged':
		case 'conversation_untagged':
		case 'conversation_rated':
		case 'conversation_typing':
			return lo.get(ev, 'data.conversation.id')
		case 'message_sent':
		case 'message_updated':
		case 'message_pong':
		case 'message_referral':
			return lo.get(ev, 'data.message.conversation_id')
		case 'ticket_updated':
			return lo.get(ev, 'data.ticket.conversation_id')
		case 'bot_terminated':
			return lo.get(ev, 'data.bot_terminated.conversation_id')
		case 'message_pinned':
		case 'message_unpinned':
			return lo.get(ev, 'data.event.data.message.conversation_id')
		case 'task_created':
		case 'task_updated':
			return lo.get(ev, 'data.conversation.id')
		default:
			return ''
	}
}

// account.acqcsmrppbftadjzxnvo.conversation.csqldpucwamyuaetjf
function convoIdFromTopic(topic) {
	if (!lo.startsWith(topic, 'account.')) return '' // invalid topic
	var topicSplit = lo.split(topic, '.')
	if (topicSplit[2] !== 'conversation') return '' // invalid topic
	return topicSplit[3] || ''
}

function convoToEv(convoid, ev) {
	if (!ev) return ''
	switch (ev.type) {
		case 'conversation_state_updated':
		case 'conversation_joined':
		case 'conversation_invited':
		case 'conversation_left':
		case 'conversation_tagged':
		case 'conversation_untagged':
		case 'conversation_typing':
			return lo.set(ev, 'data.conversation.id', convoid)
		case 'message_sent':
		case 'message_updated':
		case 'message_pong':
			return lo.set(ev, 'data.message.conversation_id', convoid)
		case 'ticket_updated':
			return lo.set(ev, 'data.ticket.conversation_id', convoid)
		case 'message_pinned':
		case 'message_unpinned':
			return lo.set(ev, 'data.event.data.message.conversation_id', convoid)
		default:
			return ''
	}
}

function setMsgField(ev, key, value) {
	var fields = lo.get(ev, 'data.message.fields') || []
	let idx = lo.findIndex(fields, (field) => field.key === key)
	if (idx > -1) {
		lo.set(fields, [idx, 'value'], JSON.stringify(value))
	} else {
		fields.push({key: key, value: JSON.stringify(value)})
	}
	lo.set(ev, 'data.message.fields', fields)
}

function trimStrArr(arr) {
	if (!arr) return []
	if (!Array.isArray(arr)) return arr
	return arr.filter((a) => (a + '').trim())
}

function getMsgField(ev, fieldName) {
	var fields = lo.get(ev, 'data.message.fields')
	if (lo.size(fields) === 0) return undefined
	let f = fields.find((f) => f.key === fieldName)
	try {
		if (f) return JSON.parse(f.value)
		return undefined
	} catch (e) {}
}

function randomString(len) {
	var str = ''
	if (!len || len < 1) len = 10
	var asciiKey
	for (var i = 0; i < len; i++) {
		asciiKey = Math.floor(Math.random() * 25 + 97)
		str += String.fromCharCode(asciiKey)
	}
	return str
}

function generatePassword(pswLeng) {
	let length = pswLeng || 12
	let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
	let retVal = ''
	for (let i = 0, n = charset.length; i < length; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n))
	}
	return retVal
}
window.generatePassword = generatePassword

function usersOfConvo(convo) {
	if (!convo) return []
	let users = lo.filter(convo.members, (m) => m.type === 'user')
	return lo.orderBy(users, 'membership', 'asc').map((m) => m.id)
}

function botsOfConvo(convo) {
	if (!convo) return []
	return lo.filter(convo.members, (m) => m.type === 'bot').map((m) => m.id)
}

function agentsOfConvo(convo, botIncluded) {
	if (!convo) return []
	return lo
		.filter(convo.members, (m) => {
			if (botIncluded) return m.type === 'agent' || m.type === 'bot'
			return m.type === 'agent' && m.membership !== 'left'
		})
		.map((m) => m.id)
}

// example url:
// content://media/external/images/media/37602
// https://subiz.com?a=4
// http://app.subiz.net/api
function isURL(str) {
	var pattern = new RegExp(
		'^((https?|content):\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i',
	) // fragment locator
	return pattern.test(str)
}

function getUrlParameter(urlPath = window.location.search, name) {
	name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
	var results = regex.exec(urlPath)
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

function getAgentDisplayName(agent, def) {
	return lo.get(agent, 'fullname') || lo.get(agent, 'email') || def || 'Agent'
}

function getNumberDisplayName(inte, short) {
	if (!inte) return 'Subiz'
	let phone = lo.split(inte.id, '.')
	phone = lo.get(phone, '1', '')
	phone = displayPhoneNumber(phone)
	if (!inte.name) return phone
	if (short) return inte.name

	if (inte.sip_provider == 'zcc') {
		let oaid = inte.id.split('.')[1]
		let oa = store.matchIntegration()[`${store.me().account_id}.${oaid}.zalokon`]
		if (oa) {
			if (short) return inte.name
			return `${inte.name} (Zalo ${oa.name})`
		}
	}

	return `${inte.name} (${phone})`
}

// try to convert startsWith 84 phone number to 0
function displayUserPhoneNumber(phone = '') {
	if (!phone.startsWith('84')) return phone
	phone = phone.replace('84', '0')
	return phone
}

function focus(obj, name) {
	// if (obj && obj.$refs && obj.$refs[name]) return obj.$refs[name].focus()
	setTimeout(() => {
		if (obj && obj.$refs && obj.$refs[name]) return obj.$refs[name].focus()
		setTimeout(() => {
			if (obj && obj.$refs && obj.$refs[name]) return obj.$refs[name].focus()
			setTimeout(() => {
				if (obj && obj.$refs && obj.$refs[name]) return obj.$refs[name].focus()
				setTimeout(() => {
					if (obj && obj.$refs && obj.$refs[name]) return obj.$refs[name].focus()
					setTimeout(() => {
						if (obj && obj.$refs && obj.$refs[name]) return obj.$refs[name].focus()
					}, 200)
				}, 100)
			}, 100)
		}, 100)
	}, 10)
}

// NOTE: could return wrong value if the time in agent machine is wrong
function isUserOnline(user) {
	return getUserBooleanAttr(user, 'focused')

	var seenms = new Date(getUserDateAttr(user, 'seen')).getTime()
	return now() - seenms <= 30 * 1000
}

function loadJsReCaptcha() {
	if (window.recaptcha) return
	let script = document.createElement('script')
	script.async = true
	script.defer = true
	script.type = 'text/javascript'
	script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded'
	document.getElementsByTagName('head')[0].appendChild(script)
}

function getOffsetTime(tz = '+07:00') {
	const [offset, _] = tz.split(':')
	return Number(offset)
}

function getUserDisplayName(user) {
	if (!user) return ''
	let name = getUserTextAttr(user, 'fullname')
	if (!name) {
		// user display name
		name = getUserTextAttr(user, 'display_name')
	}
	if (!name) {
		if (lo.get(user, 'channel') == 'zalo' && getUserBooleanAttr(user, 'is_anonymous')) {
			return i18n.t('incognito')
		}
	}
	if (name && name.trim().length > 0) {
		name = name.trim()
	} else {
		let igusername = lo.find(user.attributes, (att) => {
			let key = att.key || ''
			if (key.endsWith('fabikon_igusername') && lo.trim(att.text)) return true
		})

		if (igusername) return lo.trim(igusername.text)
		var id = lo.get(user, 'primary_id', lo.get(user, 'id', ''))
		const shortId = id.substr(id.length - 4, 4)

		let isUnkownVisitor = !lo.get(user, 'type')
		name = isUnkownVisitor ? i18n.t('unknown_visitor') : i18n.t('cust')
		name = name + ' #' + shortId
	}
	return name.trim()
}

function isUserBanned(user) {
	return getUserBooleanAttr(user, 'is_ban')
}

function setUserAttr(user, attr) {
	if (!user) return
	if (!lo.get(attr, 'key')) return

	user.attributes = user.attributes || []
	var index = lo.findIndex(user.attributes, (a) => a.key === attr.key)
	if (index === -1) {
		user.attributes.push(attr)
		return
	}
	user.attributes[index] = attr
}

function getUserAttr(user, key, type) {
	if (!lo.get(user, 'attributes.length')) return
	for (var i = 0; i < user.attributes.length; i++) {
		let attr = user.attributes[i]
		if (attr.key !== key) continue
		if (type === 'datetime') return attr.datetime
		if (type === 'boolean') return attr.boolean
		if (type === 'number') return attr.number
		if (type === 'text') return attr.text
		if (type === 'list') return attr.list

		// auto find
		lo.find(['text', 'number', 'boolean', 'datetime', 'list'], (type) => {
			if (lo.has(attr, type)) {
				return attr[type]
			}
		})

		return undefined
	}
	return undefined
}

function getUserDateAttr(user, key) {
	if (!user || !user.attributes) return
	for (var i in user.attributes) {
		var attr = user.attributes[i]
		if (attr.key !== key) continue
		return attr.datetime || new Date(0).toISOString()
	}
	return new Date(0).toISOString()
}

function getUserBooleanAttr(user, key) {
	if (!user || !user.attributes) return
	for (var i in user.attributes) {
		var attr = user.attributes[i]
		if (attr.key !== key) continue
		return attr.boolean || false
	}
	return false
}

function getUserTextAttr(user, key) {
	if (!user || !user.attributes) return
	for (var i in user.attributes) {
		var attr = user.attributes[i]
		if (attr.key !== key) continue
		return attr.text || ''
	}
	return ''
}

function getUserNumberAttr(user, key) {
	if (!user || !user.attributes) return
	for (var i in user.attributes) {
		var attr = user.attributes[i]
		if (attr.key !== key) continue
		return attr.number || 0
	}
	return 0
}

function parseJSON(str) {
	try {
		return JSON.parse(str)
	} catch (e) {}
}

function absLink(url) {
	if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('//')) return 'http://' + url
	return url
}

function absURL(path) {
	const prefixURL = lo.trim(config.FileURL, '/')
	let newPath = lo.trim(path)
	newPath = newPath.replace(prefixURL, '')
	newPath = lo.trimStart(newPath, '/')

	if (!newPath) return ''
	if (lo.startsWith(newPath, 'http')) return newPath

	return `${prefixURL}/${newPath}`
}

function replaceFileUrl(src) {
	if (!src) return ''
	if (!src.startsWith) return src
	if (!src.startsWith('http://') && !src.startsWith('https://') && !src.startsWith('//')) {
		return src
		// src = lo.trim(config.FileUrl, '/') + '/' + lo.trimStart(src, '/')
	}

	// sometime url is invalid like htts://, http://, ////
	try {
		let url = new URL(src)
		if (
			url.host.endsWith('filev4.subiz.com') ||
			url.host.endsWith('file.mysubiz.com') ||
			url.host.endsWith('file.subiz.com') ||
			url.host.endsWith('file.subiz.net') ||
			url.host.endsWith('file-subiz.com')
		) {
			let fileurl = new URL(config.FileUrl)
			url.host = fileurl.host
			url.pathname = '/file' + url.pathname
			return url.toString()
		}

		return src
	} catch (err) {
		return src
	}
}

function humanFileSize(bytes, si) {
	var thresh = si ? 1000 : 1024
	if (Math.abs(bytes) < thresh) {
		return bytes + ' B'
	}
	var units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	var u = -1
	do {
		bytes /= thresh
		++u
	} while (Math.abs(bytes) >= thresh && u < units.length - 1)
	return bytes.toFixed(1) + ' ' + units[u]
}

function noop() {}

function downloadCSV(filename, csv) {
	var blob = new Blob([csv], {type: 'text/csv;charset=utf-8,\uFEFF'})
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename)
	} else {
		var link = document.createElement('a')
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob)
			link.setAttribute('href', url)
			link.setAttribute('download', filename)
			link.style.visibility = 'hidden'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}
}

function downloadURI(uri, name) {
	var link = document.createElement('a')
	// If you don't know the name or want to use
	// the webserver default set name = ''
	link.setAttribute('download', name)
	// link.setAttribute('target', '_blank')
	link.setAttribute('href', uri)
	link.href = uri

	document.body.appendChild(link)
	link.click()
	link.remove()
}

function hNumber(num, digits) {
	const lookup = [
		{value: 1, symbol: ''},
		{value: 1e3, symbol: 'k'},
		{value: 1e6, symbol: 'M'},
		{value: 1e9, symbol: 'G'},
		{value: 1e12, symbol: 'T'},
		{value: 1e15, symbol: 'P'},
		{value: 1e18, symbol: 'E'},
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return num >= item.value
		})
	return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

function formatCredit(balance) {
	if (isNaN(balance)) return 0
	return formatNumber(Math.floor(balance / 1000000), 0)
}

function formatNumber(c = 0, perNumber = 2) {
	if (isNaN(c)) return c
	return accounting.formatNumber(Math.round(c * 100) / 100, perNumber, ',')
}

function getSelectionText() {
	var text = ''
	if (window.getSelection) {
		text = window.getSelection().toString()
	} else if (document.selection && document.selection.type != 'Control') {
		text = document.selection.createRange().text
	}
	return text
}

function unicodeToAscii(str) {
	str = str + ''
	// worka round Đ not work
	str = str.replaceAll('đ', 'd').replaceAll('Đ', 'D')
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

function capitalizeFirstLetter(string) {
	if (typeof string !== 'string') return string
	return string.charAt(0).toUpperCase() + string.slice(1)
}

function lowerCaseFirstLetter(string) {
	if (typeof string !== 'string') return string
	return string.charAt(0).toLowerCase() + string.slice(1)
}

function mobilecheck() {
	return screen.width < 600
}

function detectFileType(file) {
	let mimetype = file.mimetype || ''
	if (mimetype.includes('image/')) return 'image'
	if (mimetype.includes('audio/')) return 'audio'
	if (mimetype.includes('video/')) return 'video'
	return ''
}

// ops
function trimQuillDelta(delta) {
	if (!delta) return delta
	if (!delta.ops || !delta.ops.length) return delta
	let last = delta.ops[delta.ops.length - 1]
	if (typeof last.insert != 'string') return delta

	// change trim() to trimEnd()
	// because task note backend sometimes set insert
	let insert = last.insert.trimEnd()
	if (insert) {
		if (last.insert != insert) {
			// just fix the last item
			let newops = delta.ops.slice()
			// let last = lo.cloneDeep(newops.pop())
			let last = newops.pop()
			last.insert = insert
			newops.push(last)
			return {ops: newops}
		}

		// last item dont change, do nothing
		return delta
	}

	// remove the last item
	let newops = delta.ops.slice()
	newops.pop()
	return trimQuillDelta({ops: newops})
}

function deltaToPlainText(deltas) {
	if (deltas.ops) deltas = deltas.ops
	let output = ''
	lo.each(deltas, (delta, idx) => {
		let text = ''
		let insert = delta.insert
		let attributes = delta.attributes
		// emoji

		if (!insert) return
		if (insert.mention) text = '@' + insert.mention.fullname
		if (insert.emoji) text = emojiCodes[insert.emoji] || ''
		if (insert.dynamicField) text = `{${insert.dynamicField.key}}`
		if (typeof insert === 'string') text = insert
		output += text
	})
	return output
}

function deltaToHtml(deltas) {
	let output = ''
	lo.each(deltas, (delta, idx) => {
		let text = ''
		let insert = delta.insert
		let attributes = delta.attributes
		// only bullet
		if (attributes && attributes.list && attributes.list === 'bullet') {
			let lastinsert = output.substring(output.lastIndexOf('<br/>') + 5, lo.size(output))
			output = output.substring(0, output.lastIndexOf('<br/>') + 5) + `<ul><li>${lastinsert}</li></ul>`
			output = output.replace('</ul><br/>', '</ul>')
			output += insert.replace(/\n/g, '<br/>')
		}
		if (attributes && attributes.list && attributes.list === 'ordered') {
			let lastinsert = output.substring(output.lastIndexOf('<br/>') + 5, lo.size(output))
			output = output.substring(0, output.lastIndexOf('<br/>') + 5) + `<ol><li>${lastinsert}</li></ol>`
			output = output.replace('</ol><br/>', '</ol>')

			output += insert.replace(/\n/g, '<br/>')
		}
		// insert bold, italic, underline
		if (attributes) {
			text = insert
			if (attributes.italic) text = '<i>' + text + '</i>'
			if (attributes.bold) text = '<b>' + text + '</b>'
			if (attributes.underline) text = '<u>' + text + '</u>'
			if (attributes.strike) text = '<s>' + text + '</s>'
			if (attributes.color) text = `<span style='color: ${attributes.color};'>` + text + '</span>'
			if (attributes.background) text = `<span style='background: ${attributes.background}'>` + text + '</span>'
			if (attributes.font) text = `<span style='font-family: ${attributes.font}'>` + text + '</span>'
		}

		if (insert.emoji) text = '<span>' + emojiCodes[insert.emoji] + '</span>' || ''
		if (insert.dynamicField) text = `{${insert.dynamicField.key}}`
		if (insert.image) text = `<img alt='${insert.image}' subizimage='true' src='${insert.image}' />`
		if (!insert.emoji && !attributes && !insert.dynamicField && !insert.image) {
			text = insert || ''
			// display newline as <br> as markdown
			text = text.replace(/\n/g, '<br/>')
		}

		if (attributes && attributes.link) {
			text = `<a href="${attributes.link}">${insert}</a>`
		}

		output += text
		output = output.replace('</ol><ol>', '')
	})
	output = output.replace('</ul><br/>', '</ul>')
	output = output.replace('</ol><br/>', '</ol>')
	return output
}

function displayPercentage(num, compareNum) {
	if (num === 0) return '0%'
	let div = num / compareNum
	if (div < 0.01) return '<1%'
	return Math.round(div * 10000) / 100 + '%'
}

function convertToSlug(str) {
	let name = unicodeToAscii(str)
	return name
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '')
}

let emojiCodes = {
	like: '👍',
	unlike: '👎',
	angry: '😠',
	confused: '😕',
	crying: '😢',
	grinning: '😀',
	'heart-eyes': '😍',
	neutral: '😐',
	sleepy: '😪',
	sad: '😞',
	smiling: '😄',
	'tongue-out': '😛',
	tired: '😩',
	surprised: '😮',
	wink: '😉',
}

let operator = {
	text: [
		{op: 'any', lang: 'any'},
		{op: 'has_value', lang: 'op_has_value'},
		{op: 'is_empty', lang: 'op_is_empty'},
		{op: 'eq', lang: 'op_equal'},
		{op: 'neq', lang: 'op_not_equal'},
		{op: 'start_with', lang: 'op_start_with'},
		{op: 'end_with', lang: 'op_end_with'},
		{op: 'contain', lang: 'op_contain'},
		// {op: 'regex', lang: 'op_regex'},
		{op: 'not_contain', lang: 'op_not_contain'},
		{op: 'not_start_with', lang: 'op_not_start_with'},
		{op: 'not_end_with', lang: 'op_not_end_with'},
		{op: 'contain_all', lang: 'op_contain_all'},
	],
	number: [
		{op: 'has_value', lang: 'op_has_value'},
		{op: 'is_empty', lang: 'op_is_empty'},
		{op: 'eq', lang: 'op_equal'},
		{op: 'neq', lang: 'op_not_equal'},
		{op: 'gt', lang: 'op_greater_than'},
		{op: 'gte', lang: 'op_greater_than_or_equal'},
		{op: 'lt', lang: 'op_less_than'},
		{op: 'lte', lang: 'op_less_than_or_equal'},
		{op: 'in_range', lang: 'op_in_range'},
		{op: 'not_in_range', lang: 'op_not_in_range'},
	],
	datetime: [
		{op: 'any', lang: 'any'},
		{op: 'unset', lang: 'op_is_empty'},
		{op: 'has_value', lang: 'op_has_value'},
		{op: 'after', lang: 'op_after_date'},
		{op: 'before', lang: 'op_before_date'},
		{op: 'between', lang: 'op_between_date'},
		{op: 'outside', lang: 'op_outside_date'},
		{op: 'yesterday', lang: 'yesterday'},
		{op: 'today', lang: 'today'},
		{op: 'last_week', lang: 'last_week'},
		{op: 'this_week', lang: 'this_week'},
		{op: 'last_month', lang: 'last_month'},
		{op: 'this_month', lang: 'this_month'},

		{op: 'date_last_30mins', lang: 'last_30mins'},
		{op: 'date_last_2hours', lang: 'last_2hours'},
		{op: 'date_last_24h', lang: 'last_24h'},
		{op: 'date_last_7days', lang: 'last_7days'},
		{op: 'date_last_30days', lang: 'last_30days'},

		{op: 'in_business_hour', lang: 'in_business_hour'},
		{op: 'non_business_hour', lang: 'not_in_business_hour'},
		//{op: 'days_of_week', lang: 'op_days_of_week'},

		{op: 'date_before_3months', lang: 'before_3months'},
		{op: 'date_before_6months', lang: 'before_6months'},
		{op: 'date_before_a_year', lang: 'before_a_year'},
	],
	boolean: [
		{op: 'has_value', lang: 'op_has_value'},
		{op: 'unset', lang: 'op_is_empty'},
		{op: 'true', lang: 'op_is_true'},
		{op: 'false', lang: 'op_is_false'},
	],
}

function addScript(src) {
	var s = document.createElement('script')
	s.setAttribute('src', src)
	document.body.appendChild(s)
}

let allCurrency = [
	{name: 'Afghani', code: 'AFN', symbol: '؋'},
	{name: 'Euro', code: 'EUR', symbol: '€'},
	{name: 'Lek', code: 'ALL', symbol: 'Lek'},
	{name: 'Algerian Dinar', code: 'DZD'},
	{name: 'US Dollar', code: 'USD', symbol: '$'},
	{name: 'Kwanza', code: 'AOA'},
	{name: 'East Caribbean Dollar', code: 'XCD', symbol: '$'},
	{name: 'Argentine Peso', code: 'ARS', symbol: '$'},
	{name: 'Armenian Dram', code: 'AMD'},
	{name: 'Aruban Florin', code: 'AWG', symbol: 'ƒ'},
	{name: 'Australian Dollar', code: 'AUD', symbol: '$'},
	{name: 'Azerbaijan Manat', code: 'AZN', symbol: '₼'},
	{name: 'Bahamian Dollar', code: 'BSD', symbol: '$'},
	{name: 'Bahraini Dinar', code: 'BHD'},
	{name: 'Taka', code: 'BDT'},
	{name: 'Barbados Dollar', code: 'BBD', symbol: '$'},
	{name: 'Belarusian Ruble', code: 'BYN', symbol: 'Br'},
	{name: 'Belize Dollar', code: 'BZD', symbol: 'BZ$'},
	{name: 'CFA Franc BCEAO', code: 'XOF'},
	{name: 'Bermudian Dollar', code: 'BMD', symbol: '$'},
	{name: 'Indian Rupee', code: 'INR', symbol: '₹'},
	{name: 'Ngultrum', code: 'BTN'},
	{name: 'Boliviano', code: 'BOB', symbol: '$b'},
	{name: 'Mvdol', code: 'BOV'},
	{name: 'Convertible Mark', code: 'BAM', symbol: 'KM'},
	{name: 'Pula', code: 'BWP', symbol: 'P'},
	{name: 'Norwegian Krone', code: 'NOK', symbol: 'kr'},
	{name: 'Brazilian Real', code: 'BRL', symbol: 'R$'},
	{name: 'Brunei Dollar', code: 'BND', symbol: '$'},
	{name: 'Bulgarian Lev', code: 'BGN', symbol: 'лв'},
	{name: 'Burundi Franc', code: 'BIF'},
	{name: 'Cabo Verde Escudo', code: 'CVE'},
	{name: 'Riel', code: 'KHR', symbol: '៛'},
	{name: 'CFA Franc BEAC', code: 'XAF'},
	{name: 'Canadian Dollar', code: 'CAD', symbol: '$'},
	{name: 'Cayman Islands Dollar', code: 'KYD', symbol: '$'},
	{name: 'Chilean Peso', code: 'CLP', symbol: '$'},
	{name: 'Unidad de Fomento', code: 'CLF'},
	{name: 'Yuan Renminbi', code: 'CNY', symbol: '¥'},
	{name: 'Colombian Peso', code: 'COP', symbol: '$'},
	{name: 'Unidad de Valor Real', code: 'COU'},
	{name: 'Comorian Franc ', code: 'KMF'},
	{name: 'Congolese Franc', code: 'CDF'},
	{name: 'New Zealand Dollar', code: 'NZD', symbol: '$'},
	{name: 'Costa Rican Colon', code: 'CRC', symbol: '₡'},
	{name: 'Kuna', code: 'HRK', symbol: 'kn'},
	{name: 'Cuban Peso', code: 'CUP', symbol: '₱'},
	{name: 'Peso Convertible', code: 'CUC'},
	{name: 'Netherlands Antillean Guilder', code: 'ANG', symbol: 'ƒ'},
	{name: 'Czech Koruna', code: 'CZK', symbol: 'Kč'},
	{name: 'Danish Krone', code: 'DKK', symbol: 'kr'},
	{name: 'Djibouti Franc', code: 'DJF'},
	{name: 'Dominican Peso', code: 'DOP', symbol: 'RD$'},
	{name: 'Egyptian Pound', code: 'EGP', symbol: '£'},
	{name: 'El Salvador Colon', code: 'SVC', symbol: '$'},
	{name: 'Nakfa', code: 'ERN'},
	{name: 'Lilangeni', code: 'SZL'},
	{name: 'Ethiopian Birr', code: 'ETB'},
	{name: 'Falkland Islands Pound', code: 'FKP', symbol: '£'},
	{name: 'Fiji Dollar', code: 'FJD', symbol: '$'},
	{name: 'CFP Franc', code: 'XPF'},
	{name: 'Dalasi', code: 'GMD'},
	{name: 'Lari', code: 'GEL'},
	{name: 'Ghana Cedi', code: 'GHS', symbol: '¢'},
	{name: 'Gibraltar Pound', code: 'GIP', symbol: '£'},
	{name: 'Quetzal', code: 'GTQ', symbol: 'Q'},
	{name: 'Pound Sterling', code: 'GBP', symbol: '£'},
	{name: 'Guinean Franc', code: 'GNF'},
	{name: 'Guyana Dollar', code: 'GYD', symbol: '$'},
	{name: 'Gourde', code: 'HTG'},
	{name: 'Lempira', code: 'HNL', symbol: 'L'},
	{name: 'Hong Kong Dollar', code: 'HKD', symbol: '$'},
	{name: 'Forint', code: 'HUF', symbol: 'Ft'},
	{name: 'Iceland Krona', code: 'ISK', symbol: 'kr'},
	{name: 'Rupiah', code: 'IDR', symbol: 'Rp'},
	{name: 'SDR (Special Drawing Right)', code: 'XDR'},
	{name: 'Iranian Rial', code: 'IRR', symbol: '﷼'},
	{name: 'Iraqi Dinar', code: 'IQD'},
	{name: 'New Israeli Sheqel', code: 'ILS', symbol: '₪'},
	{name: 'Jamaican Dollar', code: 'JMD', symbol: 'J$'},
	{name: 'Yen', code: 'JPY', symbol: '¥'},
	{name: 'Jordanian Dinar', code: 'JOD'},
	{name: 'Tenge', code: 'KZT', symbol: 'лв'},
	{name: 'Kenyan Shilling', code: 'KES'},
	{name: 'North Korean Won', code: 'KPW', symbol: '₩'},
	{name: 'Won', code: 'KRW', symbol: '₩'},
	{name: 'Kuwaiti Dinar', code: 'KWD'},
	{name: 'Som', code: 'KGS', symbol: 'лв'},
	{name: 'Lao Kip', code: 'LAK', symbol: '₭'},
	{name: 'Lebanese Pound', code: 'LBP', symbol: '£'},
	{name: 'Loti', code: 'LSL'},
	{name: 'Rand', code: 'ZAR', symbol: 'R'},
	{name: 'Liberian Dollar', code: 'LRD', symbol: '$'},
	{name: 'Libyan Dinar', code: 'LYD'},
	{name: 'Swiss Franc', code: 'CHF', symbol: 'CHF'},
	{name: 'Pataca', code: 'MOP'},
	{name: 'Denar', code: 'MKD', symbol: 'ден'},
	{name: 'Malagasy Ariary', code: 'MGA'},
	{name: 'Malawi Kwacha', code: 'MWK'},
	{name: 'Malaysian Ringgit', code: 'MYR', symbol: 'RM'},
	{name: 'Rufiyaa', code: 'MVR'},
	{name: 'Ouguiya', code: 'MRU'},
	{name: 'Mauritius Rupee', code: 'MUR', symbol: '₨'},
	{name: 'ADB Unit of Account', code: 'XUA'},
	{name: 'Mexican Peso', code: 'MXN', symbol: '$'},
	{name: 'Mexican Unidad de Inversion (UDI)', code: 'MXV'},
	{name: 'Moldovan Leu', code: 'MDL'},
	{name: 'Tugrik', code: 'MNT', symbol: '₮'},
	{name: 'Moroccan Dirham', code: 'MAD'},
	{name: 'Mozambique Metical', code: 'MZN', symbol: 'MT'},
	{name: 'Kyat', code: 'MMK'},
	{name: 'Namibia Dollar', code: 'NAD', symbol: '$'},
	{name: 'Nepalese Rupee', code: 'NPR', symbol: '₨'},
	{name: 'Cordoba Oro', code: 'NIO', symbol: 'C$'},
	{name: 'Naira', code: 'NGN', symbol: '₦'},
	{name: 'Rial Omani', code: 'OMR', symbol: '﷼'},
	{name: 'Pakistan Rupee', code: 'PKR', symbol: '₨'},
	{name: 'Balboa', code: 'PAB', symbol: 'B/.'},
	{name: 'Kina', code: 'PGK'},
	{name: 'Guarani', code: 'PYG', symbol: 'Gs'},
	{name: 'Sol', code: 'PEN', symbol: 'S/.'},
	{name: 'Philippine Peso', code: 'PHP', symbol: '₱'},
	{name: 'Zloty', code: 'PLN', symbol: 'zł'},
	{name: 'Qatari Rial', code: 'QAR', symbol: '﷼'},
	{name: 'Romanian Leu', code: 'RON', symbol: 'lei'},
	{name: 'Russian Ruble', code: 'RUB', symbol: '₽'},
	{name: 'Rwanda Franc', code: 'RWF'},
	{name: 'Saint Helena Pound', code: 'SHP', symbol: '£'},
	{name: 'Tala', code: 'WST'},
	{name: 'Dobra', code: 'STN'},
	{name: 'Saudi Riyal', code: 'SAR', symbol: '﷼'},
	{name: 'Serbian Dinar', code: 'RSD', symbol: 'Дин.'},
	{name: 'Seychelles Rupee', code: 'SCR', symbol: '₨'},
	{name: 'Leone', code: 'SLL'},
	{name: 'Singapore Dollar', code: 'SGD', symbol: '$'},
	{name: 'Sucre', code: 'XSU'},
	{name: 'Solomon Islands Dollar', code: 'SBD', symbol: '$'},
	{name: 'Somali Shilling', code: 'SOS', symbol: 'S'},
	{name: 'South Sudanese Pound', code: 'SSP'},
	{name: 'Sri Lanka Rupee', code: 'LKR', symbol: '₨'},
	{name: 'Sudanese Pound', code: 'SDG'},
	{name: 'Surinam Dollar', code: 'SRD', symbol: '$'},
	{name: 'Swedish Krona', code: 'SEK', symbol: 'kr'},
	{name: 'WIR Euro', code: 'CHE'},
	{name: 'WIR Franc', code: 'CHW'},
	{name: 'Syrian Pound', code: 'SYP', symbol: '£'},
	{name: 'New Taiwan Dollar', code: 'TWD', symbol: 'NT$'},
	{name: 'Somoni', code: 'TJS'},
	{name: 'Tanzanian Shilling', code: 'TZS'},
	{name: 'Baht', code: 'THB', symbol: '฿'},
	{name: 'Pa’anga', code: 'TOP'},
	{name: 'Trinidad and Tobago Dollar', code: 'TTD', symbol: 'TT$'},
	{name: 'Tunisian Dinar', code: 'TND'},
	{name: 'Turkish Lira', code: 'TRY', symbol: '₺'},
	{name: 'Turkmenistan New Manat', code: 'TMT'},
	{name: 'Uganda Shilling', code: 'UGX'},
	{name: 'Hryvnia', code: 'UAH', symbol: '₴'},
	{name: 'UAE Dirham', code: 'AED', symbol: ' د.إ'},
	{name: 'US Dollar (Next day)', code: 'USN'},
	{name: 'Peso Uruguayo', code: 'UYU', symbol: '$U'},
	{name: 'Uruguay Peso en Unidades Indexadas (UI)', code: 'UYI'},
	{name: 'Unidad Previsional', code: 'UYW'},
	{name: 'Uzbekistan Sum', code: 'UZS', symbol: 'лв'},
	{name: 'Vatu', code: 'VUV'},
	{name: 'Bolívar Soberano', code: 'VES'},
	{name: 'Bolívar Soberano', code: 'VED'},
	{name: 'Dong', code: 'VND', symbol: '₫'},
	{name: 'Yemeni Rial', code: 'YER', symbol: '﷼'},
	{name: 'Zambian Kwacha', code: 'ZMW'},
	{name: 'Zimbabwe Dollar', code: 'ZWL'},
	{name: 'Bond Markets Unit European Composite Unit (EURCO)', code: 'XBA'},
	{name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)', code: 'XBB'},
	{name: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)', code: 'XBC'},
	{name: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)', code: 'XBD'},
	{name: 'Codes specifically reserved for testing purposes', code: 'XTS'},
	{name: 'The codes assigned for transactions where no currency is involved', code: 'XXX'},
	{name: 'Gold', code: 'XAU'},
	{name: 'Palladium', code: 'XPD'},
	{name: 'Platinum', code: 'XPT'},
	{name: 'Silver', code: 'XAG'},
]

function escapeHtml(unsafe) {
	return unsafe
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#039;')
}

function displayMoney(money, currency) {
	currency = currency || 'VND'

	let symbol = lo.find(allCurrency, (cur) => cur.code === currency) || {}
	symbol = symbol.symbol || ''

	let isMinus = money < 0
	let minusText = ''
	if (isMinus) {
		minusText = '- '
		money = Math.abs(money)
	}
	if (currency === 'VND') {
		return `${minusText}${accounting.formatNumber(money)} ${symbol}`
	}
	return `${symbol} ${minusText}${formatNumber(money)}`
}

function displayPhoneNumber(phone) {
	if (!phone.startsWith('84')) return phone

	let firstTwo = ''
	let remain = ''
	for (let i = 0; i < phone.length; i++) {
		if (i <= 1) {
			firstTwo += phone[i]
		} else {
			remain += phone[i]
		}
	}

	return `${firstTwo}${remain}`
}

function displayClockTimer(sec) {
	if (isNaN(sec)) return '--:--'
	sec = Math.floor(sec)
	if (sec >= 99 * 3600 + 59 * 60 + 59) {
		return '99:59:59'
	}

	let seconds = sec % 60
	let minutes = Math.floor(sec / 60) % 60
	let hours = Math.floor(sec / (60 * 60)) % 60

	if (!hours) {
		return `${pad(minutes)}:${pad(seconds)}`
	}

	return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
}

function pad(val) {
	val = val + ''
	if (val.length < 2) {
		return `0${val}`
	} else {
		return val
	}
}

function isSamePhoneNumber(phone1, phone2) {
	if (phone1 === phone2) return true

	return convertPhoneNumberToNormal(phone1) === convertPhoneNumberToNormal(phone2)
}

// change phone number with start 84, +84 => normal
// example: 84 98712345 => 098712345, + 84 9871231 => 09871231
function convertPhoneNumberToNormal(phone = '') {
	// remove all space
	phone = phone.replace(/ /g, '')
	if (phone.startsWith('84')) {
		phone = phone.replace('84', '0')
	} else if (phone.startsWith('+84')) {
		phone = phone.replace('+84', '0')
	}

	return phone
}

function getIntegrationIdFromConvo(convo) {
	let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')
	let pageid = lo.get(convo, 'touchpoint.source', '')
	let inteid = ''

	if (connectorType === 'subiz') {
		inteid = `${convo.account_id}.subizv4.subikon`
	}
	if (connectorType === 'facebook' || connectorType === 'facebook_comment') {
		inteid = `${convo.account_id}.${pageid}.fabikon`
	}
	if (connectorType === 'instagram' || connectorType === 'instagram_comment') {
		inteid = `${convo.account_id}.instagram_${pageid}.fabikon`
	}
	if (connectorType === 'zalo') {
		inteid = `${convo.account_id}.${pageid}.zalokon`
	}
	if (connectorType === 'call') {
		inteid = `${convo.account_id}.${pageid}.call`
	}
	if (connectorType === 'email') {
		inteid = `${convo.account_id}.mailv4.mailkon`
	}
	if (connectorType === 'google_review' || connectorType === 'google_message' || connectorType === 'google_question') {
		inteid = `${convo.account_id}.${pageid}.googlekon`
	}

	return inteid
}

function doPromiseWithTimeout(cb, ms = 3000) {
	return new Promise((resolve) => {
		if (typeof cb !== 'function') resolve()
		timeoutFn = setTimeout(() => resolve('TIMEOUT'), ms)
		let promise = cb
		if (!promise.then) promise = promise()
		if (!promise.then) resolve(promise())
		promise.then((res) => resolve(res))
	})
}

function getDomainFromUrl(url) {
	if (!url) return ''
	try {
		url = new URL(url)
		let hostname = url.hostname || ''
		if (hostname.startsWith('www.')) hostname = hostname.replace('www.', '')
		return hostname
	} catch (err) {
		console.log(url, err)
		return url
	}
}

function getFirstCharacterOfEachWords(str = '') {
	let matches = str.split(' ')
	matches = matches.map((word) => word[0] || '')
	return matches.join('')
}

class Gate {
	constructor(isOpen) {
		this.isOpen = isOpen === true
		this.cbs = []
	}

	open() {
		this.isOpen = true
		let newcbs = []
		this.cbs.forEach((cb) => {
			if (this.isOpen) cb()
			else newcbs.push(cb)
		})
		this.cbs = newcbs
	}

	close() {
		this.isOpen = false
	}

	entry(cb, lock) {
		if (this.isOpen) {
			if (lock) this.isOpen = false
			cb && cb()
			return Promise.resolve()
		}
		return new Promise((resolve) => {
			this.cbs.push(() => {
				if (lock) this.isOpen = false
				cb && cb()
				resolve()
			})
		})
	}
}

class Mutex {
	constructor() {
		this.isOpen = true
		this.cbs = []
	}

	unlock() {
		if (this.isOpen) return console.error('DUP UNLOCK')
		if (!this.cbs.length) {
			this.isOpen = true
			return
		}

		let nextcb = this.cbs.shift()
		nextcb()
	}

	lock() {
		if (this.isOpen) {
			this.isOpen = false
			return Promise.resolve()
		}

		return new Promise((resolve) => this.cbs.push(resolve))
	}
}

function getIpFromHost(host = '') {
	return lo.split(host, ':')[0]
}

// use to preevnt throw Error from date-fns format function
function format(date, fm, options) {
	// try to catch invalid date
	if (!isValidDate(date)) return ''
	return datefns.format(date, fm, options)
}

function isValidDate(d) {
	if (Number.isInteger(d)) {
		d = new Date(d)
	}
	return d instanceof Date && !isNaN(d)
}

function getCallStatusText(convo) {
	let direction = lo.get(convo, 'call.direction')
	let isMissed = lo.get(convo, 'call.is_missed')

	if (direction === 'inbound') {
		if (isMissed) {
			return i18n.t('cus_missed_call')
		} else if (convo.state === 'ended') {
			return i18n.t('cus_call_ended')
		} else if (convo.state === 'dialing') {
			return i18n.t('cus_calling')
		} else {
			return i18n.t('is_calling')
		}
	}

	if (direction === 'outbound') {
		if (isMissed) {
			return i18n.t('outbound_call_failed')
		} else if (convo.state === 'ended') {
			return i18n.t('your_call_ended')
		} else if (convo.state === 'dialing') {
			return i18n.t('you_calling')
		} else {
			return i18n.t('is_calling')
		}
	}

	return i18n.t('undefined')
}

async function blobUrlToFile(blobUrl, filename) {
	let res = await fetch(blobUrl)
	let blob = await res.blob()
	filename = filename || 'blob_url_file'

	return new File([blob], filename, {
		type: blob.type,
	})
}

async function dataURLToFile(dataurl, filename) {
	file = filename || 'base64_file'
	var arr = dataurl.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = window.atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new window.File([u8arr], filename, {type: mime})
}

async function getBlobUrlFromFile(file) {
	return new Promise((resolve, reject) => {
		var reader = new window.FileReader()
		reader.onload = (e) => {
			let base64 = e.target.result
			base64 = lo.split(base64, ',')
			let contentType = base64[0] || ''
			contentType = contentType.replace(';base64', '')
			contentType = contentType.replace('data:', '')
			let b64Data = base64[1]
			resolve(b64toBlobUrl(b64Data, contentType))
		}
		reader.readAsDataURL(file)
	})
}

const b64toBlobUrl = (b64Data, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data)
	const byteArrays = []

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize)

		const byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}

		const byteArray = new Uint8Array(byteNumbers)
		byteArrays.push(byteArray)
	}

	const blob = new Blob(byteArrays, {type: contentType})
	const blobUrl = URL.createObjectURL(blob)
	return blobUrl
}

async function getDataURLFromFile(file) {
	return new Promise((resolve, reject) => {
		var reader = new window.FileReader()
		reader.onload = (e) => {
			resolve(e.target.result)
		}
		reader.readAsDataURL(file)
	})
}

function getImageDemensionFromUrl(url) {
	return new Promise((rs) => {
		let img = new Image()
		img.onload = function () {
			rs({width: img.width, height: img.height})
		}
		img.src = url
	})
}

function getAllEmail() {
	let emails = lo.map(store.matchBusinessEmailAddress(), (email) => email)
	emails = lo.orderBy(emails, ['created', 'address'], ['desc', 'desc'])

	let intes = lo.filter(
		store.matchIntegration(),
		(inte) => inte.connector_type === 'email' && inte.state !== 'deleted' && inte.email_identity,
	)
	intes = lo.orderBy(intes, ['created'])
	let allEmail = {}
	lo.map(intes, (inte) => {
		let iden = inte.email_identity || inte.id
		let idensplit = iden.split('@')
		if (idensplit[1]) {
			allEmail[iden] = allEmail[iden] || {}
			allEmail[iden].address = iden
			allEmail[iden].verification_status = inte.email_verification_status
			allEmail[iden].fullname = inte.email_sender_name
		}
	})

	lo.map(emails, (email) => {
		if (!email.address) return
		let address = email.address.trim().toLowerCase()
		let idensplit = address.split('@')
		if (idensplit[1]) {
			allEmail[address] = allEmail[address] || {}
			allEmail[address].address = address
			allEmail[address].outbound_disabled = email.outbound_disabled || 0
			allEmail[address].inbound_disabled = email.inbound_disabled || 0
			allEmail[address].verification_status = email.verification_status || ''
			allEmail[address].fullname = email.fullname
		}
	})

	return allEmail
}

function htmlToPlainText(htmlString = '') {
	const DOMPurify = createDOMPurify()
	const clean = DOMPurify.sanitize(htmlString)

	const div = document.createElement('div')
	div.innerHTML = clean
	let result = div.textContent || div.innerText || ''
	div.remove()

	// remove all new line and tab character
	result = result.replace(/\t/g, '')
	result = result.replace(/\n/g, '')

	return result
}

async function deltaToLexicalHtml(delta) {
	let ops = lo.get(delta, 'ops', [])

	let paragraphs = []
	let pHtml = ''
	let isEndParapraph = false
	let breakLines = '' // contain multi <br />
	let isBulletList = false
	let isOrderedList = false

	for (let j = 0; j < ops.length; j++) {
		let op = ops[j]
		let insert = op.insert
		let attributes = op.attributes
		if (typeof insert === 'string') {
			let content = ''
			for (let i = 0; i < insert.length; i++) {
				let chr = insert[i]
				if (isEndParapraph && chr === '\n') {
					breakLines += '<br/>'
				} else if (chr === '\n') {
					isEndParapraph = true

					// in case new line character is start of insert
					if (insert[i + 1] && insert[i + 1] !== '\n') {
						pHtml += `<span>${content}</span>${breakLines}`
						paragraphs.push({html: pHtml, bullet_list: isBulletList, ordered_list: isOrderedList})
						pHtml = ''
						breakLines = ''
						content = ''
						isEndParapraph = false
						isBulletList = false
						isOrderedList = false
						continue
					}
				} else if (isEndParapraph) {
					pHtml += `<span>${content}</span>${breakLines}`
					paragraphs.push({html: pHtml, bullet_list: isBulletList, ordered_list: isOrderedList})
					pHtml = ''
					breakLines = ''
					content = chr
					isEndParapraph = false
					isBulletList = false
					isOrderedList = false
					continue
				} else {
					content += chr
				}
			}
			if (attributes) {
				let style = ''
				if (attributes.color) style += `color: ${attributes.color};`
				if (attributes.background) style += `color: ${attributes.background};`
				if (attributes.font) style += `font-family: ${attributes.font};`
				let contentSpan = `<span>${content}</span>`
				let boldTag = ''
				let boldTagEnd = ''
				let italicTag = ''
				let italicTagEnd = ''
				let underlineTag = ''
				let underlineTagEnd = ''
				if (attributes.bold) {
					boldTag = '<b>'
					boldTagEnd = '</b>'
				}
				if (attributes.italic) {
					italicTag = '<i>'
					italicTagEnd = '</i>'
				}
				if (attributes.underline) {
					underlineTag = '<u>'
					underlineTagEnd = '</u>'
				}
				contentSpan = `${boldTag}${italicTag}${underlineTag}${contentSpan}${underlineTagEnd}${italicTagEnd}${boldTagEnd}`
				if (attributes.link) {
					pHtml += `<a href="${attributes.link}" target="_blank" rel="noreferrer noopener">${contentSpan}</a>${breakLines}`
				} else {
					pHtml += `${contentSpan}${breakLines}`
				}

				if (attributes.list) {
					if (attributes.list === 'bullet') {
						isBulletList = true
					}
					if (attributes.list === 'ordered') {
						isOrderedList = true
					}
				}
			} else {
				pHtml += `<span>${content}</span>${breakLines}`
			}

			if (isEndParapraph) {
				paragraphs.push({html: pHtml, bullet_list: isBulletList, ordered_list: isOrderedList})
				pHtml = ''
				breakLines = ''
				isEndParapraph = false
				isBulletList = false
				isOrderedList = false
			}
		} else if (insert.dynamicField) {
			pHtml += `<span data-dynamic-field="${insert.dynamicField.key}" class="sbz-dynamic-field">${insert.dynamicField.value}</span>`
		} else if (insert.emoji) {
			const lexicalEmoji = lo.find(LEXICAL_EMOJI_LIST, (emoji) => emoji.old_code === insert.emoji)
			if (!lexicalEmoji) lexicalEmoji = {code: insert.emoji, text: insert.emoji}
			pHtml += `<span class="lexical-emoji ${lexicalEmoji.code}"><span class="lexical-emoji-inner">${lexicalEmoji.text}</span></span>`
		} else if (insert.image) {
			let {width} = await getImageDemensionFromUrl(insert.image)
			// Make sure insert img max witdh is 500px
			let imgWidth = width <= IMG_MAX_WIDTH ? width : IMG_MAX_WIDTH
			paragraphs.push({html: `<img src="${insert.image}" width="${imgWidth}">`})
		}
	}

	let result = ''
	lo.each(paragraphs, (paragraph, idx) => {
		if (paragraph.bullet_list) {
			let prev = paragraphs[idx - 1]
			let next = paragraphs[idx + 1]
			if (!prev || !prev.bullet_list) {
				result += `<ul><li>${paragraph.html}</li>`
				return
			}
			if (!next || !next.bullet_list) {
				result += `<li>${paragraph.html}</li></ul>`
				return
			}
			result += `<li>${paragraph.html}</li>`
			return
		}
		if (paragraph.ordered_list) {
			let prev = paragraphs[idx - 1]
			let next = paragraphs[idx + 1]
			if (!prev || !prev.ordered_list) {
				result += `<ol><li>${paragraph.html}</li>`
				return
			}
			if (!next || !next.ordered_list) {
				result += `<li>${paragraph.html}</li></ol>`
				return
			}
			result += `<li>${paragraph.html}</li>`
			return
		}
		result += `<p>${paragraph.html}</p>`
	})
	return result
}

const LEXICAL_EMOJI_LIST = [
	{
		code: 'thumbsup',
		text: '👍',
		old_code: 'like',
	},
	{
		code: 'thumbsdown',
		text: '👎',
		old_code: 'unlike',
	},
	{
		code: 'angry',
		text: '😠',
		old_code: 'angry',
	},
	{
		code: 'confused',
		text: '😕',
		old_code: 'confused',
	},
	{
		code: 'cry',
		text: '😢',
		old_code: 'crying',
	},
	{
		code: 'grinning',
		text: '😀',
		old_code: 'grinning',
	},
	{
		code: 'heart_eyes',
		text: '😍',
		old_code: 'heart-eyes',
	},
	{
		code: 'neutral',
		text: '😐',
		old_code: 'neutral',
	},
	{
		code: 'sleepy',
		text: '😪',
		old_code: 'sleepy',
	},
	{
		code: 'pensive',
		text: '😔',
		old_code: 'sad',
	},
	{
		code: 'smile',
		text: '😄',
		old_code: 'smiling',
	},
	{
		code: 'tongue_out',
		text: '😛',
		old_code: 'tongue-out',
	},
	{
		code: 'tired',
		text: '😫',
		old_code: 'tired',
	},
	{
		code: 'surprise',
		text: '😮',
		old_code: 'surprised',
	},
	{
		code: 'wink',
		text: '😉',
		old_code: 'wink',
	},
]

function plainTextToLexicalHtml(text = '') {
	let result = ''
	let span = ''
	for (let i = 0; i < text.length; i++) {
		let chr = text[i]
		if (chr === '\n') {
			if (span) {
				result += `<span>${span}</span><br/>`
				span = ''
			} else {
				result += '<br/>'
			}
		} else {
			span += chr
		}
	}

	result += `<span>${span}</span>`

	return `<p>${result}</p>`
}

function getFormatedResultOfDom(dom) {
	// return { bold: true | false, italic: true | false, underline: true | false }
	let bold = dom.tagName === 'B' || dom.tagName === 'STRONG'
	let italic = dom.tagName === 'I' || dom.tagName === 'EM'
	let underline = dom.tagName === 'U'

	let children = dom.children
	if (!lo.size(children)) return {bold, italic, underline}
	lo.each(children, (child) => {
		bold = bold || getFormatedResultOfDom(child).bold
		italic = italic || getFormatedResultOfDom(child).italic
		underline = underline || getFormatedResultOfDom(child).underline
	})
	return {bold, italic, underline}
}

function domToBlock(dom) {
	let children = dom.children
	let tagName = dom.tagName

	const FORMATED_TAG_NAMES = ['U', 'I', 'B', 'STRONG', 'EM']
	// in lexical, formated text always have some of this tag name, so if we want to convert
	// isBold = true, isItalic, ..., we can return if met one of these tag
	if (FORMATED_TAG_NAMES.includes(tagName)) {
		let {bold, italic, underline} = getFormatedResultOfDom(dom)
		return {
			type: 'text',
			bold,
			italic,
			underline,
			text: dom.textContent,
		}
	}

	if (tagName === 'BR') {
		return {
			type: 'text',
			text: '\n',
		}
	}

	let style = {}
	lo.each(STYLE_ATTRIBUTES, (key) => {
		if (dom.style[lo.camelCase(key)]) {
			style[key] = dom.style[lo.camelCase(key)]
		}
	})

	// this object contain block attribute eg: style, title, alt_text, href, class
	let customObj = {
		style,
		class: dom.className,
		title: dom.getAttribute('title'),
		image: {url: dom.getAttribute('src')},
		alt_text: dom.getAttribute('alt'),
		href: dom.getAttribute('href'),
	}
	let remainAttrs = {}
	lo.each(dom.attributes, (attr) => {
		if (['class', 'style', 'href', 'alt', 'src'].includes(attr.name)) return // continue
		remainAttrs[attr.name] = attr.value
	})

	// lexical emoji is <span class="heart_eyes lexical-emoji" style="white-space: pre-wrap;"><span class="lexical-emoji-inner">😍</span></span>
	// So if we met span with class lexical-emoji, return emoji block
	if (tagName === 'SPAN' && dom.classList.contains('lexical-emoji')) {
		let codeObj = lo.find(LEXICAL_EMOJI_LIST, (emoji) => {
			return dom.classList.contains(emoji.code)
		})
		if (codeObj) {
			return {
				type: 'emoji',
				attrs: {code: codeObj.code},
			}
		}

		return {
			type: 'emoji',
			attrs: {code: dom.children[0].textContent},
		}
	}

	if (!lo.size(dom.children)) {
		if (tagName === 'SPAN') {
			if (dom.getAttribute('data-dynamic-field')) {
				return {
					type: 'dynamic-field',
					text: dom.textContent,
					...customObj,
					attrs: remainAttrs,
				}
			}

			if (dom.getAttribute('data-mention')) {
				return {
					type: 'mention',
					text: dom.textContent,
					...customObj,
					attrs: remainAttrs,
				}
			}
		}

		return {
			type: TAG_TYPE_MAPS[dom.tagName] || dom.tagName,
			text: dom.textContent,
			...customObj,
			attrs: remainAttrs,
		}
	}

	return {
		type: TAG_TYPE_MAPS[dom.tagName] || dom.tagName,
		content: lo.map(children, domToBlock),
		...customObj,
		attrs: remainAttrs,
	}
}

window.lexicalHtmlToBlock = lexicalHtmlToBlock

// this is copy from message Style in header.proto
// I didnt write a tool to generate automatically yet , just copy paste only
const STYLE_ATTRIBUTES = [
	'border_radius',
	'font_famil',
	'color',
	'background',
	'text_align',
	'text_transform',
	'font_style',
	'font_weight',
	'width',
	'max_width',
	'height',
	'max_height',
	'padding_left',
	'padding_right',
	'padding_top',
	'padding_bottom',
	'margin_left',
	'margin_right',
	'margin_top',
	'margin_bottom',
	'position',
	'object_fit',
	'line_height',
	'background_position',
	'left',
	'right',
	'top',
	'bottom',
	'opacity',
	'rotate',
	'blur',
	'grayscale',
	'flex',
	'flex_direction',
	'flex_shrink',
	'align_items',
	'justify_content',
	'transform',
	'font_size',
	'z_index',
	'border_bottom',
	'border_left',
	'border_top',
	'border_right',
	'border',
	'box_shadow',
	'overflow',
	'overflow_x',
	'overflow_y',
	'white_space',
	'user_select',
	'pointer_events',
]
const TAG_TYPE_MAPS = {
	DIV: 'div',
	IMG: 'image',
	DIV: 'paragraph',
	A: 'link',
	LI: 'list_item',
	OL: 'ordered_list',
	UL: 'bullet_list',
	HR: 'horizontal_rule',
	CODE: 'code',
	SPAN: 'text',
}
// will add table logic later

function lexicalHtmlToBlock(html) {
	let div = document.createElement('div')
	div.innerHTML = html

	return {
		type: 'div',
		content: lo.map(div.children, domToBlock),
	}
}

function lexicalToPlainText(html, options = {}) {
	let result = ''
	let div = document.createElement('div')
	div.innerHTML = html

	let children = div.children
	for (let i = 0; i < children.length; i++) {
		let child = children[i]
		let tagName = child.tagName
		if (tagName !== 'P') continue

		for (const sub of child.children) {
			if (sub.tagName === 'BR') {
				result += '\n'
			} else {
				let dynamicField = sub.getAttribute('data-dynamic-field')
				let isEmoji = sub.classList.contains('lexical-emoji')
				if (options.is_show_dynamic_field_token && dynamicField) {
					result += `{${dynamicField}}`
				} else if (options.use_old_emoji && isEmoji) {
					let emojiUnicode = sub.textContent
					let emoji = lo.find(LEXICAL_EMOJI_LIST, (emoji) => emoji.text === emojiUnicode)

					if (!emoji) {
						emoji = {old_code: emojiUnicode}
					} else {
						emoji = lo.cloneDeep(emoji)
						emoji.old_code = `:${emoji.old_code}:`
					}
					result += emoji.old_code
				} else {
					result += sub.textContent
				}
			}
		}
		// insert newline between 2 P tag
		if (i < children.length - 1) {
			result += '\n'
		}
	}

	div.remove()
	result = lo.trim(result)
	return result
}

const SIP_PROVIDERS = {
	fpt: {id: 'fpt', name: 'FPT', logo: require('../assets/img/fpt_icon.png')},
	vihat: {id: 'vihat', name: 'ViHat', logo: require('../assets/img/itel_logo.png')},
	itel: {id: 'itel', name: 'iTel', logo: require('../assets/img/itel_logo.png')},
	viettel: {id: 'viettel', name: 'Viettel', logo: require('../assets/img/viettel_icon.png')},
	vnpt: {id: 'vnpt', name: 'VNPT', logo: require('../assets/img/vnpt-logo.png')},
	vina: {id: 'vina', name: 'Vinaphone', logo: require('../assets/img/vinaphone_icon.png')},
	vcc: {id: 'vcc', name: 'VCC VNPT', logo: require('../assets/img/vcc_icon.png')},
	cmc: {id: 'cmc', name: 'CMC', logo: require('../assets/img/cmc_icon.png')},
	spt: {id: 'spt', name: 'SPT', logo: require('../assets/img/spt_icon.png')},
	gtel: {id: 'gtel', name: 'GTEL', logo: require('../assets/img/logo_gtel.png')},
	zcc: {id: 'zcc', name: 'Zalo Cloud Connect', logo: require('../assets/img/zcc.png')},
	interits: {id: 'interits', name: 'Interits', logo: require('../assets/img/interit.jpeg')},
	gmobile: {id: 'gmobile', name: 'Interits', logo: require('../assets/img/interit.jpeg')},
}

function transformFbIdToFbCommentId(id) {
	let ids = lo.split(id, '.')
	let [account_id, pageid, conn] = ids
	return `${account_id}.fbcomment_${pageid}.${conn}`
}

function transformFbCommentIdToFbId(id) {
	let ids = lo.split(id, '.')
	let [account_id, pageid, conn] = ids
	pageid = pageid || ''
	pageid = pageid.replace('fbcomment_', '')
	return `${account_id}.${pageid}.${conn}`
}

function transformIgIdToIgCommentId(id) {
	let ids = lo.split(id, '.')
	let [account_id, pageid, conn] = ids
	pageid = pageid || ''
	pageid = pageid.replace('instagram_', 'igcomment_')
	return `${account_id}.${pageid}.${conn}`
}

function transformIgCommentIdToIgId(id) {
	let ids = lo.split(id, '.')
	let [account_id, pageid, conn] = ids
	pageid = pageid || ''
	pageid = pageid.replace('igcomment_', 'instagram_')
	return `${account_id}.${pageid}.${conn}`
}

function deltaToBlock(delta) {
	if (!delta) return {}
	if (typeof delta === 'string' || delta instanceof String) {
		delta = parseJSON(delta)
		if (!delta) return {}
	}

	let deltas = delta
	if (delta.ops) deltas = delta.ops

	if (lo.size(deltas) == 0) return {}

	let contents = []
	lo.map(deltas, (delta) => {
		if (!delta || !delta.insert) return
		let insert = delta.insert
		let div = {type: 'text'}

		// emoji
		if (insert.emoji) {
			// div.text = emojiCodes[insert.emoji] || ''
			contents.push({type: 'emoji', attrs: {code: insert.emoji}})
			return
		}

		if (delta.attributes) {
			if (delta.attributes.italic) div.italic = true
			if (delta.attributes.bold) div.bold = true
			if (delta.attributes.underline) div.underline = true
		}

		if (insert.dynamicField) {
			div.type = 'dynamic-field'
			div.text = insert.dynamicField.key
			div.attrs = insert.dynamicField
		} else {
			div.text = insert || ''
		}

		if (insert.hyperlink) {
			let user = insert.hyperlink.user || {id: ''}
			let convo = insert.hyperlink.conversation || {id: ''}

			let copydiv = Object.assign({}, div)
			if (user.id || convo.id) {
				let link = `/convo?cid=${convo.id}&uid=${user.id}`
				contents.push(
					Object.assign(copydiv, {
						type: 'link',
						href: link,
						target: '_blank',
						text: link,
					}),
				)
				return
			}
		}

		if (typeof insert === 'string') {
			extractText(insert).map((tag, i) => {
				let copydiv = Object.assign({}, div)
				if (tag.type === 'link') {
					contents.push(
						Object.assign(copydiv, {
							type: 'link',
							href: absLink(tag.text),
							target: '_blank',
							text: tag.text,
						}),
					)
					return
				}

				contents.push(Object.assign(copydiv, {text: tag.text}))
			})
			return
		}

		if (insert.mention) {
			div.type = 'mention'
			div.text = '@' + lo.get(insert, 'mention.fullname', '')
			div.attrs = {id: insert.mention.id}
			contents.push(div)
			return
		}

		contents.push(div)
	})

	if (contents.length == 0) return {}
	if (contents.length == 1) return contents[0]
	return {type: 'paragraph', content: contents}
}

window.deltaToBlock = deltaToBlock

function blockToProseMirror(item) {
	if (lo.size(item) == 0) return [{type: 'paragraph'}]
	if (item.type == 'div') {
		// just wraper
		return lo.map(item.content, (item) => _blockToProseMirror(item)).filter((node) => node && node.type)
	}

	let node = _blockToProseMirror(item)
	if (!node || !node.type) return [{type: 'paragraph'}]
	if (node.type == 'heading') return [node]
	if (node.type != 'paragraph') return [{type: 'paragraph', content: [node]}]
	return node.content // return of content paragraph
}

function _blockToProseMirror(item) {
	if (!lo.size(item)) return {}
	let node = {type: item.type}
	if (item.type == 'text') {
		node.text = item.text
	}
	// convert type div to paragraph of prosemirror
	if (item.type === 'div') {
		node.type = 'paragraph'
		// block empty line is {type; 'div', content: [{type: 'hard_break'}]}
		// we need to convert to prosemirror empty line is {type: 'paragraph'}
		if (lo.size(item.content) === 1 && lo.get(item, 'content.0.type') === 'hard_break') {
			return {type: 'paragraph'}
		}
	}

	let marks = []
	if (item.bold) marks.push({type: 'strong'})
	if (item.italic) marks.push({type: 'em'})
	if (item.underline) marks.push({type: 'underline'})
	if (item.strike) marks.push({type: 'strike'})
	if (item.code) marks.push({type: 'code'})

	if (item.type === 'link') {
		node.type = 'text'
		marks.push({
			type: 'link',
			attrs: {
				href: item.href,
				title: null,
			},
		})
		node.text = item.text
	}
	if (item.type === 'pre') {
		node.type = 'code_block'
	}
	let style = item.style
	if (style) {
		if (style.color) marks.push({type: 'color', attrs: {color: style.color || '#000'}})
		if (style.background_color)
			marks.push({type: 'background_color', attrs: {background_color: style.background_color}})
	}

	if (marks.length) node.marks = marks

	if (item.type == 'dynamic-field') node.attrs = item.attrs
	if (item.type == 'emoji') node.attrs = item.attrs
	if (item.type === 'mention') {
		node.attrs = item.attrs
	}
	if (item.type == 'ordered_list') node.attrs = {order: item.order}
	if (item.type === 'image') {
		node.attrs = {
			src: item.image.url,
			alt: null,
			title: null,
		}
	}
	if (item.type === 'heading') {
		node.attrs = {level: item.level}
	}
	if (item.type === 'table_cell') {
		node.attrs = {
			colspan: item.colspan,
			rowspan: item.rowspan,
			colwidth: item.colwidth || null,
			background: item.background || null,
		}
	}

	if (item.content && item.content.length > 0) {
		node.content = lo.map(item.content, (child) => _blockToProseMirror(child))
	}

	return node
}

window.blockToProseMirror = blockToProseMirror

function proseMirrorNodeToBlock(item) {
	if (!item) return {}
	let elementObj = {}
	elementObj.type = item.type
	elementObj.style = {}

	if (item.type === 'text') elementObj.text = item.text
	if (item.type === 'paragraph') elementObj.type = 'div' // like gmail and hotmail
	if (item.marks) {
		item.marks.forEach((mark) => {
			if (mark.type === 'strong') elementObj.bold = true
			if (mark.type === 'em') elementObj.italic = true
			if (mark.type === 'link') {
				elementObj.type = 'link'
				elementObj.href = mark.attrs.href
				elementObj.title = mark.attrs.title || ''
			}
			if (mark.type === 'underline') elementObj.underline = true
			if (mark.type === 'strike') elementObj.strike = true
			if (mark.type === 'code') elementObj.code = true
			if (mark.type === 'color') {
				let color = lo.get(mark, 'attrs.color', '')
				if (color) elementObj.style.color = color
			}
			if (mark.type === 'background_color') {
				let bg_color = lo.get(mark, 'attrs.background_color', '')
				if (bg_color) elementObj.style.backgroundColor = bg_color
			}
		})
	}
	if (item.type === 'code_block') {
		elementObj.type = 'pre'
	}
	if (item.type == 'dynamic-field') {
		elementObj.type = 'dynamic-field'
		elementObj.attrs = item.attrs
	}

	if (item.type == 'emoji') {
		elementObj.type = 'emoji'
		elementObj.attrs = item.attrs
	}

	if (item.type == 'mention') {
		elementObj.attrs = item.attrs
	}

	if (item.type === 'image') {
		elementObj.alt_text = item.attrs.title || ''
		elementObj.image = {
			url: item.attrs.src,
			//width: 100,
			//height: 100,
		}
	}

	if (item.attrs && item.type != 'image') {
		for (const key in item.attrs) {
			let value = item.attrs[key]
			if (value) elementObj[key] = value
		}
	}

	if (!lo.size(elementObj.style)) delete elementObj.style

	return elementObj
}
// input: null, [{}], {} => {}
// [{type: 'paraph', content: [{type: text, text: 'hello', marks: [{type: 'strong'}]}]}]

function proseMirrorToBlock(document) {
	if (!document) return {}
	let out = lo.map(document, (node) => _proseMirrorToBlock(node)).filter((out) => lo.size(out) > 0)
	if (lo.size(out) == 0) return {}
	if (lo.size(out) == 1) return out[0]
	return {type: 'div', content: out}
}

window.proseMirrorToBlock = proseMirrorToBlock

function _proseMirrorToBlock(node) {
	if (!node) return {}
	let ele = proseMirrorNodeToBlock(node)
	if (lo.size(node.content) == 0) {
		// breakline is a { type: 'div' }, we need to add <br/> tag inside
		if (ele.type === 'div') {
			return {
				...ele,
				content: [{type: 'hard_break'}],
			}
		}
		return ele
	}
	ele.content = lo.map(node.content, (item) => _proseMirrorToBlock(item))
	return ele
}

function loopBlock(block, func) {
	if (!block) return
	func(block)
	lo.map(block.content, (block) => {
		func(block)
	})
}

function blockToText(block, options = {is_show_dynamic_field_token: false}) {
	return lo.trim(_blockToText(block, options))
}

function _blockToText(block, options) {
	if (!lo.size(block)) return ''
	let out = ''
	if (block.type == 'bullet_list' || block.type == 'ordered_list') {
		lo.map(block.content, (item) => {
			preifx = '\n* '
			if (block.type == 'ordered_list') {
				prefix = i + '\n. '
			}
			out += prefix + lo.trim(_blockToText(item, options))
		})
		return out
	}

	if (block.type == 'heading' || block.type == 'paragraph' || block.type == 'div') {
		out += '\n'
	}

	if (block.type == '' || block.type == 'text' || block.type == 'link' || block.type == 'mention') {
		return out + (block.text || '')
	}

	if (block.type === 'dynamic-field') {
		if (options.is_show_dynamic_field_token) {
			let text = lo.get(block, 'attrs.key') || ''
			if (text) {
				text = `{${text}}`
			} else {
				text = block.text || ''
			}
			return out + text
		}
		return out + (block.text || '')
	}

	if (block.type == 'horizontal_rule') return out + '\n---\n'
	if (block.type == 'emoji') {
		let code
		if (lo.size(block.attrs) > 0) {
			code = block.attrs['code']
		}
		if (code == '') {
			code = block.text
		}

		if (code != '') {
			let emoji = lo.find(LEXICAL_EMOJI_LIST, (emoji) => emoji.code === code)
			out + lo.get(emoji, 'text', code)
		}
		return out
	}

	lo.map(block.content, (block) => {
		out += _blockToText(block, options)
	})
	return out
}

function orderAgents(agents) {
	if (!lo.size(agents)) return []
	let orderedAgents = agents
	orderedAgents = lo.orderBy(
		orderedAgents,
		['state', (agent) => unicodeToAscii(agent.fullname).toLowerCase()],
		['asc', 'asc'],
	)
	return orderedAgents
}

function roundFpvMoney(value = 0, options = {currency: 'VND', round_thoundsand_vnd: false}) {
	let currency = options.currency
	if (currency === 'USD') {
		value = value / 1_000_000
		value = Math.round(value * 100) / 100
		return value
	}

	if (options.round_thoundsand_vnd) {
		value = Math.round(value / 1_000_000)
		if (value % 1000 === 0) return value
		return Math.round(value / 1000) * 1000
	}
	return Math.round(value / 1_000_000)
}

function setCookie(name, value, days) {
	var expires = ''
	if (days) {
		var date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = '; expires=' + date.toUTCString()
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=.' + window.location.hostname
}

function getBankName(bin) {
	switch (bin) {
		case '970400':
			return 'SaigonBank'
		case '970403':
			return 'Sacombank'
		case '970405':
			return 'Agribank'
		case '970406':
			return 'DongABank'
		case '970407':
			return 'Techcombank'
		case '970408':
			return 'GPBank'
		case '970409':
			return 'BacABank'
		case '970410':
			return 'StandardChartered'
		case '970412':
			return 'PVcomBank'
		case '970414':
			return 'Oceanbank'
		case '970415':
			return 'VietinBank'
		case '970416':
			return 'ACB'
		case '970418':
			return 'BIDV'
		case '970419':
			return 'NCB'
		case '970421':
			return 'VRBank'
		case '970422':
			return 'MBBank'
		case '970423':
			return 'TPBank'
		case '970424':
			return 'ShinhanBank'
		case '970425':
			return 'ABBank'
		case '970426':
			return 'MSB'
		case '970427':
			return 'VietABank'
		case '970428':
			return 'NamABank'
		case '970429':
			return 'SCB'
		case '970430':
			return 'PGBank'
		case '970431':
			return 'Eximbank'
		case '970432':
			return 'VPBank'
		case '970433':
			return 'VietBank'
		case '970434':
			return 'IndovinaBank'
		case '970436':
			return 'Vietcombank'
		case '970437':
			return 'HDBank'
		case '970438':
			return 'BaoVietBank'
		case '970439':
			return 'PublicBank'
		case '970440':
			return 'SeABank'
		case '970441':
			return 'VIB'
		case '970442':
			return 'HongLeong'
		case '970443':
			return 'SHB'
		case '970444':
			return 'CBBank'
		case '970446':
			return 'COOPBANK'
		case '970448':
			return 'OCB'
		case '970449':
			return 'LPBank'
		case '970452':
			return 'KienLongBank'
		case '970454':
			return 'VietCapitalBank'
		case '970455':
			return 'IBKHN'
		case '970456':
			return 'IBKHCM'
		case '970457':
			return 'Woori'
		case '970458':
			return 'UnitedOverseas'
		case '970459':
			return 'CIMBBank'
		case '970460':
			return 'Vietcredit'
		case '970462':
			return 'KookminHN'
		case '970463':
			return 'KookminHCM'
		case '970464':
			return 'TNEXFinance'
		case '970465':
			return 'SINOPAC'
		case '970466':
			return 'KEBHanaHCM'
		case '970467':
			return 'KEBHANAHN'
		case '970468':
			return 'MAFC'
		case '970470':
			return 'MCredit'
	}
	return ''
}

function getCookie(c_name) {
	var i,
		x,
		y,
		ARRcookies = document.cookie.split(';')
	for (i = 0; i < ARRcookies.length; i++) {
		x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='))
		y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1)
		x = x.replace(/^\s+|\s+$/g, '')
		if (x == c_name) {
			return unescape(y)
		}
	}
}

const mentionNodeSpec = {
	attrs: {name: {default: ''}, id: {default: ''}, type: {default: ''}},
	inline: true,
	group: 'inline',
	draggable: true,
	toDOM: (node) => [
		'mention',
		{
			mention: true,
			'data-type': node.attrs.type,
			'data-name': node.attrs.name,
			'data-id': node.attrs.id,
			style: 'display: inline-block',
			class: 'mention',
		},
		'@' + node.attrs.name,
	],
	parseDOM: [
		{
			tag: 'mention[mention]',
			getAttrs: (dom) => {
				let node = {}
				node.name = dom.getAttribute('data-name')
				node.type = dom.getAttribute('data-type')
				node.id = dom.getAttribute('data-id')
				return node
			},
		},
	],
}

const emojiNodeSpec = {
	attrs: {code: {default: 'like'}},
	inline: true,
	group: 'inline',
	draggable: true,

	toDOM: (node) => ['emj', {'emoji-code': node.attrs.code, class: 'emoji emoji__' + node.attrs.code}],
	parseDOM: [
		{
			tag: 'emj[emoji-code]',
			getAttrs: (dom) => {
				let code = dom.getAttribute('emoji-code')
				return EmojiList.indexOf(code) > -1 ? {code} : false
			},
		},
	],
}

const dynamicFieldNodeSpec = {
	attrs: {id: {default: ''}, key: {default: ''}, value: {default: ''}},
	inline: true,
	group: 'inline',
	draggable: true,

	toDOM: (node) => [
		'dfe',
		{
			'dynamic-field-key': node.attrs.key,
			'data-id': node.attrs.id,
			'data-value': node.attrs.value,
			class: 'dynamic-field',
		},
		node.attrs.value || node.attrs.id || 'undefined',
	],
	parseDOM: [
		{
			tag: 'dfe[dynamic-field-key]',
			getAttrs: (dom) => {
				let node = {}
				node.value = dom.getAttribute('data-value')
				node.id = dom.getAttribute('data-id')
				node.key = dom.getAttribute('dynamic-field-key')
				return node
			},
		},
	],
}

window.blockToText = blockToText
module.exports = {
	plural,
	snackCase,
	dynamicFieldNodeSpec,
	emojiNodeSpec,
	mentionNodeSpec,
	loadJsReCaptcha,
	randomString,
	convoOfEv,
	roundFpvMoney,
	usersOfConvo,
	convoToEv,
	getMsgField,
	setMsgField,
	isURL,
	agentsOfConvo,
	botsOfConvo,
	getUrlParameter,
	getAgentDisplayName,
	isUserBanned,
	noop,
	getOffsetTime,
	convoIdFromTopic,
	crc32,
	getUserDisplayName,
	getUserAttr,
	setUserAttr,
	getUserTextAttr,
	getUserDateAttr,
	isUserOnline,
	getUserBooleanAttr,
	getUserNumberAttr,
	parseJSON,
	absURL,
	humanFileSize,
	downloadURI,
	downloadCSV,
	replaceFileUrl,
	formatNumber,
	getSelectionText,
	unicodeToAscii,
	capitalizeFirstLetter,
	lowerCaseFirstLetter,
	mobilecheck,
	detectFileType,
	deltaToPlainText,
	deltaToHtml,
	hNumber,
	trimQuillDelta,
	operator,
	displayPercentage,
	trimStrArr,
	convertToSlug,
	addScript,
	allCurrency,
	escapeHtml,
	displayMoney,
	Gate,
	displayPhoneNumber,
	displayClockTimer,
	getNumberDisplayName,
	generatePassword,
	isSamePhoneNumber,
	getIntegrationIdFromConvo,
	getIpFromHost,
	doPromiseWithTimeout,
	Mutex,
	getDomainFromUrl,
	format,
	getFirstCharacterOfEachWords,
	getCallStatusText,
	focus,
	dataURLToFile,
	blobUrlToFile,
	getBlobUrlFromFile,
	getDataURLFromFile,
	b64toBlobUrl,
	getImageDemensionFromUrl,
	getAllEmail,
	htmlToPlainText,
	SIP_PROVIDERS,
	deltaToLexicalHtml,
	formatCredit,
	plainTextToLexicalHtml,
	lexicalToPlainText,
	lexicalHtmlToBlock,
	LEXICAL_EMOJI_LIST,
	displayUserPhoneNumber,
	transformIgCommentIdToIgId,
	transformIgIdToIgCommentId,
	transformFbIdToFbCommentId,
	transformFbCommentIdToFbId,
	isTopicIdConvo,
	deltaToBlock,
	blockToProseMirror,
	proseMirrorToBlock,
	loopBlock,
	blockToText,
	orderAgents,
	getCookie,
	setCookie,
	getBankName,
	snakeCaseToCapitalCamelCase,
}

//window.proseMirrorToBlock = proseMirrorToBlock

// FOR test deltaToLexicalHtml
//const delta1 = {
//ops: [
////{insert: 'Chu '},
////{attributes: {bold: true}, insert: 'hoa'},
////{insert: ' chu '},
////{attributes: {italic: true}, insert: 'thuong'},
////{attributes: {underline: true}, insert: 'chu'},
////{insert: ' in '},
////{attributes: {underline: true, italic: true, bold: true}, insert: 'ngieng'},
////{insert: ' chu '},
////{attributes: {color: '#e60000', underline: true}, insert: 'dam'},
////{insert: '\n'},
////{attributes: {font: 'monospace'}, insert: 'Lorem'},
////{insert: ' ipsum '},
////{attributes: {background: '#ff9900'}, insert: 'dolore'},
//{insert: ' ssit amit\nwefewfew'},
//{attributes: {list: 'bullet'}, insert: '\n'},
//{insert: 'efwefwfw'},
//{attributes: {list: 'bullet'}, insert: '\n'},
//{insert: '\n'},
//],
//}
