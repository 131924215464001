const loadingimg = require('../assets/img/loading_static.svg')
const notfound = require('../assets/img/not-found.png')
const emptymsgimg = require('../assets/img/integration-help-image.png')
import MessageEvent from '../activities/conversation/messageEvent.js'
import Reaction from '../activities/conversation/reaction.js'
import CallConvoCard from '../activities/conversation/call_convo_card.js'

import {format} from 'date-fns'
import {getDateFnsLocale} from '../languages.js'

const sb = require('@sb/util')
import store from '@sb/store'
import Icon from './icon.js'
import BotEditor from '../bot/bot_texteditor.js'
// var editReplyMessage = ''
import lightGallery from 'lightgallery'

export default {
	name: 'events',
	props: ['com', 'cid', 'hidden', 'uid', 'show_ai_message_tip'],
	data() {
		return {
			loadingMore: false,
			outofmsg: false,
			error: false,
			loading: true,
			isShrinked: false,
			old_cids: [],
			isEditing: false,

			// this state use for trigger all messageEvent for new messageUpdated event
			messageUpdated: 0,
		}
	},

	created() {
		this.editReplyMessage = ''
		this.distanceBottom = 0
		this.loadConvo(this.cid)
		if (this.cid) setTimeout(() => store.markReadConvo(this.cid), 1000)

		store.onConvo(this, (convoM) => {
			if (Object.keys(convoM).includes(this.cid)) {
				this.forceUpdate()
			}
		})

		store.onMessage(this, (convoid, _, ev) => {
			// leak info &pinned, should fix
			if (convoid !== this.cid && convoid + '&pinned' !== this.cid) return
			this.$forceUpdate()

			if (convoid === this.cid && ev && ev.type === 'message_sent') {
				if (ev.by.id == store.me().id) this.scrollBottom()
				else if (this.distanceBottom < 200) this.scrollBottom()

				let isbottom = this.distanceBottom < 100
				if (isbottom && document.hasFocus() && !this.hidden) store.markReadConvo(this.cid)
			}
			this.computeGalleryImages()
		})

		store.onMessageUpdated(this, async (ev) => {
			let cid = lo.get(ev, 'data.message.conversation_id')
			if (cid !== this.cid) return
			store.resetEventAnchor(cid)
			await store.fetchMoreMessages(cid)
			this.messageUpdated = Date.now()
			this.$forceUpdate()
		})

		store.onDocumentFocus(this, (val) => {
			if (!this.hidden) store.markReadConvo(this.cid)
		})

		store.onTask(this, (task) => {
			if (!task) return
			if (lo.includes(task.associated_users, this.uid)) {
				this.forceUpdate()
			}
		})

		store.onUserEvent(this, (userid) => {
			if (userid === this.uid) this.$forceUpdate()
		})
	},

	watch: {
		cid(cid, oldcid) {
			if (!this.hidden) store.markReadConvo(cid)
			this.old_cids = []
			this.isEditing = false
			this.loadingMore = false
			this.loadConvo(cid)
		},
	},

	beforeDestroy() {
		if (this.gallery && typeof this.gallery.destroy === 'function') this.gallery.destroy()
	},

	methods: {
		forceUpdate: lo.throttle(
			function () {
				this.$forceUpdate()
			},
			1000,
			{leading: false},
		),
		onContainerClick() {
			// ignore select behaviour
			var selection = window.getSelection()
			if (selection.toString().length > 0) return
			if (this.$refs.msg_input) this.$refs.msg_input.Focus()
			if (this.distanceBottom < 100) store.markReadConvo(this.cid)
		},

		async loadConvo(cid) {
			this.error = false
			if (!cid) return

			this.old_cids = []
			this.scrollBottom()
			this.outofmsg = false
			// this.error = false
			store.resetEventAnchor(this.cid)
			this.loadMoreEvents()
			this.error = false
		},

		onShowGallery({evid, attachment_idx}) {
			let imgIdx = lo.findIndex(
				this.galleryImages,
				(image) => image.evid === evid && image.attachment_idx === attachment_idx,
			)
			if (imgIdx < 0) {
				return this.$showError(this.$t('not_found') + ' ' + `${evid}_${attachment_idx}`)
			}

			if (!this.gallery) this.initGallery()
			this.gallery.openGallery(imgIdx)
		},

		computeGalleryImages() {
			let convo = store.matchConvo(this.cid) || {id: this.cid}
			let channel = lo.get(convo, 'touchpoint.channel')
			if (channel === 'call' || channel === 'form') return []

			let events = lo.orderBy(this.com.listMessages(this.cid), ['created'], 'asc')
			events = lo.filter(events, (ev) => {
				return lo.get(ev, 'type') === 'message_sent' && lo.get(ev, 'data.message.conversation_id') === this.cid
			})
			lo.each(this.old_cids, (cid) => {
				let oldEvents = lo.orderBy(this.com.listMessages(cid), ['created'], 'asc')
				oldEvents = lo.filter(oldEvents, (ev) => {
					return lo.get(ev, 'type') === 'message_sent' && lo.get(ev, 'data.message.conversation_id') === cid
				})
				events = [...oldEvents, ...events]
			})
			let galleryImages = []
			lo.each(events, (ev) => {
				let attachments = lo.get(ev, 'data.message.attachments') || []
				lo.each(attachments, (att, idx) => {
					if (lo.includes(att.mimetype, 'image')) {
						galleryImages.push({...att, _created: ev.created, evid: ev.id, attachment_idx: idx})
					}
				})
			})
			this.galleryImages = galleryImages
			this.initGallery()
		},

		initGallery() {
			let galleryElements = lo.map(this.galleryImages, (att) => ({
				src: att.url,
				thumb: att.thumbnail_url || att.url,
			}))
			if (!this.gallery) {
				let div = document.createElement('div')
				this.gallery = lightGallery(div, {
					dynamic: true,
					dynamicEl: galleryElements,
				})
			} else {
				this.gallery.refresh(galleryElements)
			}
		},

		async loadMoreEvents() {
			if (this.outofmsg) return
			if (this.loadingMore) return
			this.loadingMore = true

			let lastthiscid = this.cid
			let cid = this.cid

			let convo = store.matchConvo(cid)
			if (lo.get(convo, 'touchpoint.channel') === 'call') {
				this.error = false
				this.loading = 0
				return
			}

			if (this.old_cids.length > 0) {
				cid = this.old_cids[this.old_cids.length - 1]
			}

			if (lo.size(this.com.listMessages2(this.uid, cid)) === 0) {
				this.loading = Date.now()
				setTimeout(() => this.$forceUpdate(), 1000) // render spinning icon
			}

			let {end} = await this.com.fetchMoreMessages2(this.uid, cid)
			if (lastthiscid !== this.cid) return // outdated
			this.loading = 0

			if (end) {
				let convo = store.matchConvo(this.cid) || {}
				let channel = lo.get(convo, 'touchpoint.channel')
				let source = lo.get(convo, 'touchpoint.source')
				let id = lo.get(convo, 'touchpoint.id')
				if (
					(channel == 'facebook' || channel == 'instagram' || channel == 'zalo' || channel == 'google_message') &&
					source &&
					id
				) {
					let all_cids = await store.listConvosOfTouchpoint(channel, source, id)
					if (lastthiscid !== this.cid) return // outdated
					// push
					let nextcid = ''
					for (let i = 0; i < all_cids.length; i++) {
						let acid = all_cids[i]
						// if (acid == this.cid) return false
						if (acid >= this.cid) continue
						let found = lo.find(this.old_cids, (oldcid) => oldcid == acid)
						if (!found) {
							nextcid = acid
							break
						}
					}
					if (!nextcid) {
						this.loadingMore = false
						this.outofmsg = true
						return
					}

					this.old_cids.push(nextcid)
					await this.com.fetchMoreMessages2(this.uid, nextcid)
					if (lastthiscid !== this.cid) return // outdated
					// ignore the output and
					// must set 'end' to false so we later load more message
					end = false
				}
			}
			this.loadingMore = false
			this.outofmsg = end
			this.computeGalleryImages()

			// keep loading until end or not at the top
			let list = this.$refs.msg_container
			if (list && !end) {
				let {scrollHeight, scrollTop, clientHeight} = list
				let distToTop = scrollHeight - clientHeight + scrollTop
				if (distToTop < 500) this.loadMoreEvents()
				this.distanceBottom = -scrollTop
			}
		},

		onWheelScroll: lo.throttle(
			function (e) {
				if (!this.cid) return
				let list = e.target
				let {scrollHeight, scrollTop, clientHeight} = list
				let distToTop = scrollHeight - clientHeight + scrollTop
				if (distToTop < 500) this.loadMoreEvents()
				this.distanceBottom = -scrollTop
			},
			20,
			{trailing: true},
		),

		scrollBottom() {
			let $list = this.$refs.msg_container
			if (!$list) return
			$list.scrollTo({top: 0}) // $list.scrollTop = $list.scrollHeight
		},

		renderError() {
			let $tryAgainBtn = (
				<div class='mt-2 link' vOn:click={(_) => this.loadConvo(this.cid)}>
					{this.$t('try_again')} <Icon name='rotate' class='ml-2' size='14' />
				</div>
			)
			if (this.loading) $tryAgainBtn = <Spinner class='mt-2' size='16' mode='blue' />
			return (
				<div class='messages_bg justify-content-center align-items-center pl-5 pr-5'>
					<img src={require('../assets/img/empty_1.svg')} />
					<div class='mt-5 text__muted text__center'>{this.$t('load_convo_error')}</div>
					<div style='height: 20px; display: flex; align-items: center'>{$tryAgainBtn}</div>
				</div>
			)
		},

		countReaction(ev) {
			let pongs = lo.get(ev, 'data.message.pongs', [])
			let countReaction = {
				like: 0,
				love: 0,
				haha: 0,
				wow: 0,
				sad: 0,
				angry: 0,
				me_liked: 0,
				hide: 0,
				delete: 0,
			}
			lo.forEach(pongs, (reaction) => {
				let memberId = lo.get(reaction, 'member_id', '').toString()
				if (reaction.type === 'like' && memberId && memberId.indexOf('us') < 0) {
					countReaction.me_liked++
				}
				countReaction[reaction.type]++
			})
			return countReaction
		},

		pushReaction(ev, type) {
			let message = lo.get(ev, 'data.message', {})
			if (!type) return

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})
			if (isDelete) return

			message = lo.cloneDeep(message)

			let fields = [
				{key: 'facebook_comment_type', value: JSON.stringify('command')},
				{key: 'facebook_comment_command', value: JSON.stringify(type)},
				{key: 'facebook_comment_target_id', value: JSON.stringify(ev.id)},
				{key: 'facebook_comment_comment_id', value: JSON.stringify(sb.getMsgField(ev, 'facebook_comment_comment_id'))},
			]
			message.fields = fields
			message.text = type
			this.$emit('submitMsg', message)
		},

		pushIgReaction(ev, type) {
			let message = lo.get(ev, 'data.message', {})
			if (!type) return

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})
			if (isDelete) return

			message = lo.cloneDeep(message)
			let fields = [
				{key: 'instagram_comment_type', value: JSON.stringify('command')},
				{key: 'instagram_comment_command', value: JSON.stringify(type)},
				{key: 'instagram_comment_event_id', value: JSON.stringify(ev.id)},
				{
					key: 'instagram_comment_comment_id',
					value: JSON.stringify(sb.getMsgField(ev, 'instagram_comment_comment_id')),
				},
			]
			message.fields = fields
			message.text = type
			this.$emit('submitMsg', message)
		},

		renderFbCommentActions(ev) {
			let byType = lo.get(ev, 'by.type')
			let countReaction = this.countReaction(ev)

			let fbPostLink = sb.getMsgField(ev, 'facebook_comment_permalink_url')
			let $detail = null
			if (fbPostLink) {
				$detail = (
					<a href={fbPostLink} target='_blank' class='link link__secondary mr-3'>
						{this.$t('detail')}
					</a>
				)
			}

			let isDelete = false
			lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
			})
			if (isDelete) {
				return null
			}

			let $like = (
				<div
					style={isDelete ? 'pointer-events: none' : ''}
					vOn:click={(_) => this.pushReaction(ev, 'like')}
					class='link link__secondary mr-3'
				>
					{this.$t('like')}
				</div>
			)

			if (countReaction.me_liked) {
				$like = (
					<div
						style={isDelete ? 'pointer-events: none' : ''}
						vOn:click={(_) => this.pushReaction(ev, 'unlike')}
						class='link link__secondary mr-3'
					>
						{this.$t('unlike')}
					</div>
				)
			}
			if (byType !== 'user') {
				$like = null
			}

			let $delete = (
				<Icon
					name='trash'
					size='13'
					vOn:click={(_) => this.pushReaction(ev, 'delete')}
					title={this.$t('delete')}
					style='position: relative; top: -1px;'
					stroke-width='2'
					class='link link__secondary mr-3'
				/>
			)

			let $hide = (
				<div
					style={isDelete ? 'pointer-events: none' : ''}
					vOn:click={(_) => this.pushReaction(ev, 'hide')}
					class='link link__secondary mr-3'
				>
					{this.$t('hide')}
				</div>
			)
			if (countReaction.hide) {
				$hide = (
					<div
						style={isDelete ? 'pointer-events: none' : ''}
						vOn:click={(_) => this.pushReaction(ev, 'unhide')}
						class='link link__secondary mr-3'
					>
						{this.$t('unhide')}
					</div>
				)
			}

			let $inbox = (
				<div class='link link__primary mr-3' vOn:click={() => this.onInboxClick(ev)}>
					{this.$t('inbox')}
				</div>
			)
			if (byType !== 'user') $inbox = null
			// too late to inbox
			if (Date.now() - ev.created > 7 * 86400000) {
				$inbox = null
			} else {
				if (sb.getMsgField(ev, 'facebook_comment_private_replied')) {
					$inbox = null
				}
			}

			return (
				<div class='d-flex align-items-center mt-1' style='font-size: 12px; margin-left: 15px'>
					{$like}
					{$hide}
					{$detail}
					{$inbox}
					{$delete}
				</div>
			)
		},

		renderIgCommentActions(ev) {
			let byType = lo.get(ev, 'ev.by.type')
			let $agent = null
			if (byType === 'agent') {
				let name = this.$t('you')
				let byId = lo.get(ev, 'ev.by.id')
				let ag = lo.get(store.matchAgent(), byId)
				if (byId !== lo.get(store.me(), 'id')) {
					name = sb.getAgentDisplayName(ag)
				}
				$agent = (
					<span class='text__muted mr-3'>
						<Avatar agent={ag} size='12' style='position: relative; margin-top: 2px' />
						&nbsp;
						{name}
					</span>
				)
			}

			let isDelete = false
			let isHide = false
			lo.map(lo.get(ev, 'ev.data.message.pongs', []), (pong) => {
				if (pong.type === 'delete') isDelete = true
				if (pong.type === 'hide') isHide = true
			})
			if (isDelete) {
				return (
					<div class='d-flex align-items-center mt-1' style='position: relative; font-size: 12px; margin-left: 15px'>
						{$agent}
						<Time ago time={ev.ev.created} class='text__muted' />
					</div>
				)
			}

			let $inbox = (
				<div class='link link__primary mr-3' vOn:click={() => this.onInboxClick(ev.ev)}>
					{this.$t('inbox')}
				</div>
			)
			if (byType !== 'user') $inbox = null

			let $hide = (
				<div vOn:click={(_) => this.pushIgReaction(ev.ev, 'hide')} class='ml-3 link link__secondary mr-3'>
					{this.$t('hide')}
				</div>
			)
			if (isHide) {
				$hide = (
					<div vOn:click={(_) => this.pushIgReaction(ev.ev, 'unhide')} class='ml-3 link link__primary mr-3'>
						{this.$t('unhide')}
					</div>
				)
				$inbox = null
			}

			// too late to inbox
			if (Date.now() - ev.ev.created > 7 * 86400000) {
				$inbox = null
			} else {
				if (sb.getMsgField(ev, 'instagram_comment_private_replied')) {
					$inbox = null
				}
			}

			if (byType !== 'user') $hide = <div class='mr-3'></div>

			if (ev.ev._sending)
				return (
					<div class='d-flex align-items-center mt-1' style='position: relative; font-size: 12px; margin-left: 15px'>
						<Spinner mode='blue' size='12' title={this.$t('sending')} />
					</div>
				)

			return (
				<div class='d-flex align-items-center mt-1' style='position: relative; font-size: 12px; margin-left: 15px'>
					{$agent}
					<Time ago time={ev.ev.created} class='text__muted ' />
					{$hide}
					{$inbox}
					<icon
						name='trash'
						size='14'
						vOn:click={(_) => this.pushIgReaction(ev.ev, 'delete')}
						title={this.$t('delete')}
						style='position: relative; top: -1px;'
						stroke-width='2'
						class='link link__secondary mr-3'
					/>
				</div>
			)
		},

		onInboxClick(ev) {
			// only for inbox
			let convo = store.matchConvo(this.cid) || {}
			let touchpoint = lo.get(convo, 'touchpoint') || {}
			let connectorType = touchpoint.channel
			if (connectorType !== 'facebook_comment' && connectorType !== 'instagram_comment') return

			let comment_id = ''
			if (connectorType === 'facebook_comment') {
				comment_id = sb.getMsgField(ev, 'facebook_comment_comment_id')
			}

			if (connectorType === 'instagram_comment') {
				comment_id = sb.getMsgField(ev, 'instagram_comment_comment_id')
			}

			let user = store.matchUser(ev.by.id, true) || {}
			if (!user.profile_id) return this.$showError(this.$t('send_message_failed'))
			let newtouchpoint = {
				id: user.profile_id,
				channel: connectorType == 'facebook_comment' ? 'facebook' : 'instagram',
				source: touchpoint.source,
			}

			this.$emit('startNewConvo', {user_id: ev.by.id, comment_id: comment_id, touchpoint: newtouchpoint})
			return
		},

		renderSendingIcon(ev, receive) {
			let byType = lo.get(ev, 'by.type')
			let $sending = null
			if (byType !== 'user') {
				if (ev._sending) {
					$sending = (
						<Icon
							name='circle'
							class='message_state message_state__sending'
							style='right: -20px'
							size='12'
							stroke-width='3'
							title={this.$t('sending')}
						/>
					)
				} else if (receive === true) {
					$sending = (
						<Icon
							name='check'
							class='message_state message_state__received'
							style='right: -20px'
							size='10'
							stroke-width='4'
							title={this.$t('msg_received')}
						/>
					)
				} else if (receive === false) {
					$sending = (
						<Icon
							name='check'
							class='message_state message_state__sent'
							style='right: -20px'
							size='10'
							stroke-width='4'
							title={this.$t('sent')}
						/>
					)
				}
			}
			if (ev._error) {
				$sending = null
			}

			return $sending
		},

		renderFacebookComments(events) {
			let convo = store.matchConvo(this.cid) || {}
			let connectorType = lo.get(convo, 'touchpoint.channel')
			if (connectorType !== 'facebook_comment') return null

			let pageid = lo.get(convo, 'touchpoint.source')

			let sentMessageEvents = lo.filter(events, (ev) => lo.get(ev, 'ev.type') === 'message_sent') || []
			sentMessageEvents = lo.orderBy(sentMessageEvents, [(ev) => lo.get(ev, 'ev.created')])
			let [postEvent, ...remainMessages] = sentMessageEvents
			if (!postEvent) return null
			let $img = null
			let attachments = lo.get(postEvent, 'ev.data.message.attachments', [])
			let fields = lo.get(postEvent, 'ev.data.message.fields', [])
			let img = lo.find(attachments, (att) => (att.mimetype || '').startsWith('image'))
			if (img)
				$img = (
					<img
						style='width: 100%; border-radius: 8px; max-height: 200px; object-fit: cover; '
						src={img.url}
						class='mt-2'
					/>
				)
			let postCreated = lo.get(postEvent, 'ev.created')
			postCreated = new Date(postCreated)
			let link = sb.getMsgField(postEvent.ev, 'facebook_comment_permalink_url')

			let inteid = sb.getIntegrationIdFromConvo(convo)
			let inte = store.matchIntegration()[inteid] || {}
			let $comments = lo.map(remainMessages, (ev, idx) => {
				if (sb.getMsgField(ev.ev, 'facebook_comment_type') === 'command') return null
				let $avatar = null
				let $name = null
				let byType = lo.get(ev, 'ev.by.type')
				let byId = lo.get(ev, 'ev.by.id')
				if (byType === 'user') {
					let user = store.matchUser(byId) || {}
					$avatar = <Avatar user={user} size='24' style='position: relative; top: 12px' />
					$name = <span class='text__semibold'>{sb.getUserDisplayName(user)}</span>
				} else {
					$avatar = (
						<img
							style='width: 24px; height: 24px; object-fit: cover; border-radius: 50%;'
							src={sb.replaceFileUrl(inte.logo_url || '')}
						/>
					)
					$name = <span class='text__semibold'>{inte.name}</span>
					let $agent = <span class='text__muted'>({this.$t('you')})</span>
					if (byId !== lo.get(store.me(), 'id')) {
						let ag = lo.get(store.matchAgent(), byId)
						if (ag) {
							$agent = <span class='text__muted'>({lo.get(ag, `fullname`)})</span>
						} else {
							$agent = null
						}
					}
					$name = (
						<Fragment>
							{$name} {$agent}
						</Fragment>
					)
				}
				let created = lo.get(ev, 'ev.created')
				created = new Date(created)

				let $img = null
				let attachments = lo.get(ev, 'ev.data.message.attachments', [])
				let fields = lo.get(ev, 'ev.data.message.fields', [])
				let img = lo.find(attachments, (att) => (att.mimetype || '').startsWith('image'))
				if (img)
					$img = (
						<img
							style='width: 100px; border-radius: 8px; max-height: 80px; object-fit: cover;'
							src={img.url}
							class='mt-2'
						/>
					)

				let isDelete = false
				let isHide = false
				lo.map(lo.get(ev, 'ev.data.message.pongs', []), (pong) => {
					if (pong.type === 'delete') isDelete = true
					if (pong.type === 'hide') isHide = true
				})

				let wrappercls = 'facebook_comment_message_wrapper'
				if (idx === 0) wrappercls += ' first'
				if (lo.get(ev, 'ev._error')) wrappercls += ' error'

				return (
					<div style={isDelete ? 'opacity: 0.5' : ''} class={wrappercls}>
						{$avatar}
						<div class='ml-3' style='overflow: hidden'>
							<div class={`facebook_comment_message ${byType}`} style={isHide ? 'opacity: 0.5' : ''}>
								<Reaction ev={ev.ev} style='position: absolute; margin: 0; right: -4px; bottom: -4px;' />
								<div>
									{$name}
									&nbsp;&nbsp;
									<small>
										<Time ago time={ev.ev.created} class='text__muted' />
									</small>
								</div>
								<div style={isDelete ? 'text-decoration: line-through' : ''}>{lo.get(ev, 'ev.data.message.text')}</div>
								{$img}
								{this.renderSendingIcon(ev.ev, ev.receive)}
							</div>
							{lo.get(ev, 'ev._error') ? (
								<Fragment>
									<small class='text__danger'>{this.$t('sent_message_error')}:</small>
									<br />
									<small class='text__danger text__truncate'>{lo.get(ev, 'ev._error', '')}</small>
								</Fragment>
							) : (
								this.renderFbCommentActions(ev.ev)
							)}
						</div>
					</div>
				)
			})

			let text = lo.get(postEvent, 'ev.data.message.text', '')
			let $showMore = null
			if (text.length > 150) {
				if (this.isShrinked) {
					text = text.substring(0, 150) + '...'
					$showMore = (
						<span class='ml-2 link link__secondary text__semibold' vOn:click={() => (this.isShrinked = false)}>
							{this.$t('show_more')}
						</span>
					)
				} else {
					$showMore = (
						<span class='ml-2 link link__secondary text__semibold' vOn:click={() => (this.isShrinked = true)}>
							{this.$t('see_less')}
						</span>
					)
				}
			}
			return (
				<div class='facebook_comment_card'>
					<div class='facebook_comment_card_post'>
						<div class='facebook_comment_card_post_header'>
							<img class='facebook_comment_card_post_img' src={sb.replaceFileUrl(inte.logo_url || '')} />
							<div class='ml-3'>
								<div class='text__semibold'>{inte.name}</div>
								<div style='font-size: 13px'>
									<span class='text__muted'>
										<Time time={postCreated} />
									</span>
									&nbsp; &nbsp;
									<a href={link} target='_blank'>
										{this.$t('view_detail')}
									</a>
									&nbsp; &nbsp;
									<Icon
										name='copy'
										class='x-icon'
										size='13'
										stroke-width='2'
										title={this.$t('copy_fb_post_id')}
										vOn:click={() => {
											let sourceid = lo.get(convo, 'touchpoint.source')
											let postid = lo.get(convo, 'touchpoint.id')
											postid = lo.split(postid, '_')
											postid = postid[0]

											sb.copyToClipboard(`${sourceid}_${postid}`)
											this.$showSuccess(this.$t('copied_to_clipboard'))
										}}
									/>
								</div>
							</div>
						</div>
						<div class='text__muted mt-3'>
							{text} {$showMore}
						</div>
						{$img}
					</div>
					<div class='facebook_comment_card_comments'>{$comments}</div>
				</div>
			)
		},

		renderInstagramComments(events) {
			let convo = store.matchConvo(this.cid) || {}
			let connectorType = lo.get(convo, 'touchpoint.channel')
			if (connectorType !== 'instagram_comment') return null

			let pageid = lo.get(convo, 'touchpoint.source')

			let sentMessageEvents = lo.filter(events, (ev) => lo.get(ev, 'ev.type') === 'message_sent') || []
			sentMessageEvents = lo.orderBy(sentMessageEvents, [(ev) => lo.get(ev, 'ev.created')])
			let postEvent = sentMessageEvents[0]
			if (!postEvent) return null
			let $img = null
			let attachments = lo.get(postEvent, 'ev.data.message.attachments', [])
			let fields = lo.get(postEvent, 'ev.data.message.fields', [])
			let img = lo.find(attachments, (att) => (att.mimetype || '').startsWith('image'))
			if (img) $img = <img style='width: 100%; height: 100%; object-fit: contain;' src={img.url} />
			let postCreated = sb.getMsgField(postEvent.ev, 'instagram_comment_post_created')
			if (!postCreated) postCreated = lo.get(sentMessageEvents[0], 'ev.created')
			postCreated = new Date(postCreated)
			let link = sb.getMsgField(postEvent.ev, 'instagram_comment_permalink_url')
			sentMessageEvents.shift()
			sentMessageEvents.reverse()
			let inteid = sb.getIntegrationIdFromConvo(convo)
			let inte = store.matchIntegration()[inteid] || {}
			let $comments = lo.map(sentMessageEvents, (ev, idx) => {
				if (sb.getMsgField(ev.ev, 'instagram_comment_type') === 'command') return null
				let $avatar = null
				let $name = null
				let byType = lo.get(ev, 'ev.by.type')
				let byId = lo.get(ev, 'ev.by.id')

				if (byType === 'user') {
					let user = store.matchUser(byId) || {}
					$avatar = <Avatar user={user} size='28' />
					$name = <span class='text__semibold'>{sb.getUserDisplayName(user)}</span>
				} else {
					$avatar = (
						<img
							style='width: 28px; height: 28px; object-fit: cover; border-radius: 50%;'
							src={sb.replaceFileUrl(inte.logo_url || '')}
						/>
					)
					$name = <span class='text__semibold'>{inte.name}</span>
				}
				let created = lo.get(ev, 'ev.created')
				created = new Date(created)

				let isDelete = false
				let isHide = false
				lo.map(lo.get(ev, 'ev.data.message.pongs', []), (pong) => {
					if (pong.type === 'delete') isDelete = true
					if (pong.type === 'hide') isHide = true
				})

				return (
					<div style={isDelete || isHide ? 'opacity: 0.6' : ''} class='instagram_comment_message_wrapper'>
						{$avatar}
						<div>
							<div class={`instagram_comment_message ${byType}`}>
								<Reaction ev={ev.ev} style='position: absolute; margin: 0; right: -4px; bottom: -4px;' />
								<div>
									{$name}
									&nbsp;&nbsp;
									<span style={isDelete ? 'text-decoration: line-through' : ''}>
										{lo.get(ev, 'ev.data.message.text')}
									</span>
								</div>
							</div>
							{this.renderIgCommentActions(ev)}
						</div>
					</div>
				)
			})

			let text = lo.get(postEvent, 'ev.data.message.text', '')
			let $showMore = null
			if (text.length > 100) {
				if (this.isShrinked) {
					text = text.substring(0, 100) + '...'
					$showMore = (
						<span class='ml-2 link link__secondary text__semibold' vOn:click={() => (this.isShrinked = false)}>
							{this.$t('show_more')}
						</span>
					)
				} else {
					$showMore = (
						<span class='ml-2 link link__secondary text__semibold' vOn:click={() => (this.isShrinked = true)}>
							{this.$t('see_less')}
						</span>
					)
				}
			}
			return (
				<div class='instagram_comment_card'>
					<div class='instagram_comment_preview'>{$img}</div>
					<div class='instagram_comment_content'>
						<div class='instagram_comment_card_post_header'>
							<img class='facebook_comment_card_post_img' src={sb.replaceFileUrl(inte.logo_url || '')} />
							<div class='ml-3'>
								<div class='text__semibold'>{inte.name}</div>
								<div style='font-size: 13px'>
									<span class='text__muted'>
										{format(postCreated, 'HH:mm, dd MMM, yyyy', {locale: getDateFnsLocale()})}
									</span>
									&nbsp; &nbsp;
									<a href={link} target='_blank'>
										{this.$t('view_detail')}
									</a>
								</div>
							</div>
						</div>

						<div class='instagram_comment_card_comments'>
							<div style='flex: 1'></div>
							{$comments}
							<div class='instagram_comment_message_wrapper'>
								<img
									style='width: 28px; height: 28px; object-fit: cover; border-radius: 50%;'
									src={sb.replaceFileUrl(lo.get(inte.logo_url || ''))}
								/>
								<div>
									<div class={`instagram_comment_message agent`}>
										<div>
											<span class='text__semibold'>{inte.name}</span>
											&nbsp;&nbsp;
											<span>
												{text} {$showMore}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},

		async editGoogleReview() {
			this.isEditing = true
			this.$forceUpdate()

			await this.$nextTick()
			let $editor = this.$refs[`editor_reply`]
			$editor && $editor.focus()
		},

		async deleteGoogleReview() {
			let cf = await this.$confirm({
				title: this.$t('delete_review'),
				description: this.$t('delete_review'),
				style: 'danger',
				ok: this.$t('delete'),
				cancel: this.$t('cancel'),
			})

			if (!cf) return null
			let fields = [
				{key: 'google_review_type', value: JSON.stringify('command')},
				{key: 'google_review_command', value: JSON.stringify('delete')},
			]
			let message = {
				fields: fields,
				type: 'plaintext',
				text: 'delete',
			}

			this.$emit('submitMsg', message)
		},

		async submitReply() {
			let message = this.editReplyMessage
			if (!message) return
			this.isEditing = false
			this.$emit('submitMsg', {text: message, type: 'plaintext'})
			this.$forceUpdate()
		},

		cancelEditting() {},

		renderGoogleReviewAction() {
			let convo = store.matchConvo(this.cid) || {}
			let googleReview = lo.get(convo, 'google_review')
			let isRemove = lo.get(convo, 'google_review.is_removed')

			let $edit = (
				<div
					class={isRemove ? 'link link__secondary mr-3' : 'link link__primary mr-3'}
					style={isRemove ? 'pointer-events: none' : ''}
					vOn:click={(_) => convo.state === 'active' && this.editGoogleReview()}
				>
					{this.$t('edit')}
				</div>
			)

			let $delete = (
				<Icon
					name='trash'
					size='16'
					vOn:click={(_) => this.deleteGoogleReview()}
					title={this.$t('delete')}
					style={{position: 'relative', top: '-1px'}}
					stroke-width='2'
					class='link link__secondary'
				/>
			)

			let $cancel = (
				<div
					class='link link__danger'
					vOn:click={(_) => {
						if (!lo.get(googleReview, 'review_rely.comment')) delete googleReview.review_rely.comment
						this.isEditing = false
						this.$forceUpdate()
					}}
				>
					{this.$t('cancel')}
				</div>
			)

			let $post = (
				<div class='link link__primary mr-3' vOn:click={(_) => this.submitReply()}>
					{this.$t('post')}
				</div>
			)

			return (
				<div class='d-flex align-items-center mt-1' style='font-size: 16px; justify-content: space-between'>
					{!this.isEditing && $edit}
					{!this.isEditing && $delete}
					{this.isEditing && $post}
					{this.isEditing && $cancel}
				</div>
			)
		},

		renderGoogleReview(events) {
			let convo = store.matchConvo(this.cid) || {}
			let connectorType = lo.get(convo, 'touchpoint.channel')
			if (connectorType !== 'google_review') return null

			let name = lo.get(convo, 'integration.name')
			let address = lo.get(convo, 'integration.google_review_address')
			let googleReview = lo.get(convo, 'google_review')
			this.editReplyMessage = lo.get(googleReview, 'review_rely.comment') || ''
			let isRemoved = lo.get(googleReview, 'is_removed')

			let userName = lo.get(googleReview, 'reviewer.display_name')
			let userPhoto = lo.get(googleReview, 'reviewer.profile_photo_url')
			let $text = <div style={isRemoved ? 'text-decoration: line-through' : ''}>{lo.get(googleReview, 'comment')}</div>

			let $stars = []
			for (let i = 0; i < googleReview.rating; i++) {
				$stars.push(<Icon name='star-filled' size='16' color='rgb(250, 187, 5)' />)
			}
			for (let i = googleReview.rating; i < 5; i++) {
				$stars.push(<Icon name='star' size='16' />)
			}

			let $replybtn = (
				<div class='d-flex align-items-center mt-2' style='font-size: 16px; justify-content: space-between'>
					<div
						class={`link ${!isRemoved ? `link__primary` : `link__secondary`} mr-3`}
						vOn:click={(_) => {
							lo.set(googleReview, 'review_rely.comment', '')
							this.isEditing = true
							this.$forceUpdate()
						}}
						style={{pointerEvents: isRemoved ? 'none' : ''}}
					>
						{this.$t('reply_review')}
					</div>
				</div>
			)

			let $review = (
				<div class='location_review' style={{display: 'flex', opacity: isRemoved ? '0,5' : '1'}}>
					{/* <Avatar user={user} size='md' /> */}
					<img style='width: 40px; height: 40px; object-fit: cover; border-radius: 50%;' src={userPhoto} />
					<div
						class='ml-3'
						style={{
							backgroundColor: '#f0f0f0',
							padding: '10px 15px',
							borderRadius: '10px',
							flex: 1,
						}}
					>
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<div class='text__semibold' style={{fontSize: '16px'}}>
								{userName}
							</div>
							<Time ago time={googleReview.created} class='text__muted ml-3' style={{fontSize: '13px'}} />
						</div>
						<div class='mb-1'>{$stars}</div>
						{$text}
						{lo.get(googleReview, 'review_rely.comment') == undefined && !isRemoved && $replybtn}
					</div>
				</div>
			)

			let agentId = lo.get(googleReview, 'review_rely.agent_id')
			let $avatar = agentId ? (
				<Avatar agent={store.matchAgent(agentId)} size='sm' nodot />
			) : (
				<img
					style='width: 26px; height: 26px; object-fit: cover; border-radius: 50%;'
					src={require('./../assets/img/subiz2.svg')}
				/>
			)

			let message = {quill_delta: JSON.stringify({ops: [{insert: this.editReplyMessage}]})}
			let $reply = (
				<div class='location_review_reply' style='paddingLeft: 50px; margin-top: 20px; display: flex;'>
					{$avatar}
					<div class='ml-3' style={{backgroundColor: '#e4f3fd', padding: '10px 15px', borderRadius: '10px', flex: 1}}>
						<div class='mb-1' style={{display: 'flex', justifyContent: 'space-between'}}>
							<div class='text__semibold' style={{fontSize: '16px'}}>
								{this.$t('response_from_owner')}
							</div>
							<Time
								ago
								time={lo.get(googleReview, 'review_rely.updated')}
								class='text__muted'
								style={{fontSize: '13px'}}
							/>
						</div>
						{!this.isEditing ? (
							<div>{lo.get(googleReview, 'review_rely.comment')}</div>
						) : (
							<BotEditor
								ref={`editor_reply`}
								style='background: #f0f2f5; border: none; border-radius: 13px;'
								message={message}
								actions={['']}
								vOn:change={(mes) => (this.editReplyMessage = mes.text)}
							/>
						)}
						{!isRemoved && this.renderGoogleReviewAction()}
					</div>
				</div>
			)

			let removeStyle = ''
			if (isRemoved) removeStyle += 'filter: grayscale(1); opacity: 0.6;'

			return (
				<div class='google_review_card' style={removeStyle}>
					<div class='google_review_card_post'>
						<div class='text__muted' style={{fontSize: '14px'}}>
							Google Review
						</div>
						<div class='google_review_location_name'>{name}</div>
						<div class='google_review_location_address text__muted' style={{fontSize: '16px'}}>
							{address}
						</div>
					</div>
					<div class='google_review_card_comments'>
						<div>{$review}</div>
						{lo.get(googleReview, 'review_rely.comment') != undefined && $reply}
					</div>
				</div>
			)
		},

		onClickBackground(e) {
			// dont focus editor when try to copy text
			if (window.getSelection().toString()) return
			this.$emit('clickBackground', e)
		},
	},

	render() {
		if (this.error) return this.renderError()
		if (this.loading) {
			if (Date.now() - this.loading > 600)
				return (
					<div class='messages_bg justify-content-center align-items-center pl-5 pr-5'>
						<Spinner mode='dark' size='30' center />
						<div class='text-center text__muted mt-4'>{this.$t('loading_message')}</div>
					</div>
				)
			return <div class='messages_bg justify-content-center align-items-center pl-5 pr-5'></div>
		}

		if (!store.matchConvo(this.cid)) {
			return (
				<div class='convo_events' data-convo-id={this.cid} style='justify-content: flex-end;'>
					<img class='convo_events__empty_img' src={emptymsgimg} />
				</div>
			)
		}

		let convo = store.matchConvo(this.cid)
		let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')

		if (connectorType === 'call') {
			return (
				<div class={`convo_events convo_events__comment`} data-convo-id={this.cid} ref='msg_container' key={this.cid}>
					<div style='flex: 1; display: flex; align-items: flex-start; justify-content: center;'>
						<CallConvoCard cid={this.cid} class='mt-5' />
					</div>
				</div>
			)
		}

		let events = lo.orderBy(this.com.listMessages2(this.uid, this.cid), ['created'], 'asc')
		// only display sent_message event cross channel from email, fb_commment and instagram comment
		events = lo.filter(events, (ev) => {
			if (lo.get(ev, 'type') !== 'message_sent') return true
			if (lo.get(ev, 'data.message.conversation_id') === this.cid) return true
			if (
				connectorType === 'email' ||
				connectorType === 'facebook_comment' ||
				connectorType === 'instagram_comment' ||
				connectorType == 'call'
			) {
				return lo.get(ev, 'data.message.conversation_id') === this.cid
			}

			let evchannel = lo.get(ev, 'touchpoint.channel')
			return evchannel === 'email' || evchannel === 'facebook_comment' || evchannel === 'instagram_comment'
		})

		// find the last ev sent
		events = events.map((ev) => ({ev}))
		processEvents(convo, events)

		if (lo.size(events) === 0) {
			return (
				<div class='d-flex align-items-center ' style='flex-direction: column'>
					<img style='margin-top: 200px; opacity: 0.5' src={notfound} />
					<div class='mt-5 text__muted'>{this.$t('convo_no_message')}</div>
				</div>
			)
		}

		let inteid = sb.getIntegrationIdFromConvo(convo)
		let inte = store.matchIntegration()[inteid] || {}
		// asc
		events.reverse()
		console.log('111111111111', JSON.parse(JSON.stringify(events)))
		let $msgs = events.map((ev, i) => (
			<MessageEvent
				vOn:submitMsg={(e) => this.$emit('submitMsg', e)}
				vOn:quote={(e) => this.$emit('quote', e)}
				vOn:bookmark={(e) => this.$emit('bookmark', e)}
				vOn:gallery={this.onShowGallery}
				vOn:reply={(ev) => this.$emit('reply', ev)}
				key={ev.ev.id}
				uid={this.uid}
				cid={convo.id}
				show_ai_message_tip={this.show_ai_message_tip}
				integration_connector_type={connectorType}
				integration_name={inte.name}
				integration_logo_url={inte.logo_url}
				eid={ev.ev.id}
				updated={ev.ev.updated || ev.ev.created}
				followers={ev.followers}
				seen_last_by={ev.seen_last_by}
				receive={ev.receive}
				mode={ev.mode}
				show_time={ev.show_time}
				vOn:blockEmail={(e) => this.$emit('blockEmail', e)}
				messageUpdated={this.messageUpdated}
				vOn:focusEvent={(ev) => {
					let $el = document.querySelector(`.message_container_wrapper[data-id=${lo.get(ev, 'id')}]`)
					if ($el) {
						console.log('focusEvent', $el, ev)
						this.$root.$emit('focusEvent', ev)
						$el.scrollIntoViewIfNeeded()
					}
				}}
			/>
		))

		let $old_msgs = []
		// old_cid must be sorted
		lo.map(this.old_cids, (cid) => {
			let events = lo.orderBy(this.com.listMessages(cid), ['created'], 'asc').map((ev) => ({ev}))
			let convo = store.matchConvo(cid) || {}
			processEvents(convo, events)

			// asc
			events.reverse()
			let $msgs = events.map((ev, i) => (
				<MessageEvent
					style='filter: grayscale(1);'
					vOn:gallery={this.onShowGallery}
					vOn:submitMsg={(e) => this.$emit('submitMsg', e)}
					vOn:quote={(e) => this.$emit('quote', e)}
					vOn:bookmark={(e) => this.$emit('bookmark', e)}
					vOn:reply={(ev) => this.$emit('reply', ev)}
					key={ev.ev.id}
					cid={cid}
					integration_connector_type={connectorType}
					integration_name={inte.name}
					integration_logo_url={inte.logo_url}
					eid={ev.ev.id}
					updated={ev.ev.updated || ev.ev.created}
					followers={ev.followers}
					seen_last_by={ev.seen_last_by}
					receive={ev.receive}
					mode={ev.mode}
					show_time={ev.show_time}
					uid={this.uid}
					vOn:blockEmail={(e) => this.$emit('blockEmail', e)}
				/>
			))
			if ($msgs.length > 0) {
				$msgs.unshift(
					<div style='margin: 10px auto; border-bottom: 2px dashed #a1a1a1; padding: 10px; width: 300px;'></div>,
				)
			}

			$old_msgs = $old_msgs.concat($msgs)
		})

		let $loadmore = (
			<div class='d-flex align-items-center justify-content-center mt-4 mb-4'>
				<div style='height: 20px'>
					{this.outofmsg ? (
						<div class='text__muted'>{this.$t('conversation_started')}</div>
					) : (
						<a href='#' onClick={this.loadMoreEvents}>
							{this.$t('load_more')}
						</a>
					)}
				</div>
			</div>
		)
		if (this.loadingMore) {
			$loadmore = (
				<div class='d-flex align-items-center justify-content-center mt-4 mb-4'>
					<Spinner size='20' mode='blue' />
				</div>
			)
		}

		let $suggest = null
		if (
			convo.state === 'ended' &&
			(connectorType == 'subiz' ||
				connectorType == 'zalo' ||
				connectorType == 'instagram' ||
				connectorType == 'facebook' ||
				connectorType == 'google_message')
		) {
			$suggest = (
				<div class='justify-content-center align-items-center d-flex'>
					<div class='btn btn__success mt-3 mb-3' vOn:click={(_) => this.$emit('startNewConvo')}>
						<icon name='message' size='20' class='mr-2' stroke-width='1.75' style='margin-top: -2px' />
						{this.$t('start_a_new_conversation')}
					</div>
				</div>
			)
		}

		// if conversation ended
		if (connectorType === 'facebook_comment') {
			return (
				<div class={`convo_events convo_events__comment`} data-convo-id={this.cid} ref='msg_container'>
					<div style='flex: 1'>{this.renderFacebookComments(events)}</div>
				</div>
			)
		}

		if (connectorType === 'instagram_comment') {
			return (
				<div class={`convo_events convo_events__comment`} data-convo-id={this.cid} ref='msg_container'>
					<div style='flex: 1; overflow: hidden; display: flex; align-items: center'>
						{this.renderInstagramComments(events)}
					</div>
				</div>
			)
		}

		if (connectorType === 'google_review') {
			return (
				<div class={`convo_events convo_events__comment`} data-convo-id={this.cid} ref='msg_container'>
					<div style='flex: 1'>{this.renderGoogleReview(events)}</div>
				</div>
			)
		}

		return (
			<div
				class={`convo_events ${connectorType}`}
				data-convo-id={this.cid}
				ref='msg_container'
				vOn:scroll={this.onWheelScroll}
				vOn:click={this.onClickBackground}
			>
				<div style='margin-bottom: auto'></div>
				{$suggest}
				{$msgs}
				{$old_msgs}
				{$loadmore}
			</div>
		)
	},
}

// [{ev: {}, mode: 'time'}]
//_mode = time
//_mode = lead
function processEvents(convo, events) {
	if (events.length < 1) return
	for (let i = 0; i < events.length - 1; i++) {
		if (events[i].ev.type == 'content_viewed' && events[i + 1].ev.type == 'content_viewed') {
			events[i].ev._grouped = events[i].ev._grouped || 1
			events[i + 1].ev._grouped = events[i].ev._grouped + 1
			events[i].ev._display = false
		}
	}

	events[0].show_time = true
	// insert time
	for (let i = 1; i < events.length; i++) {
		let curEvent = events[i]
		let lastEvent = events[i - 1]

		if (visibleEvent(curEvent.ev)) {
			let elapsedSec = sb.unixSec(curEvent.ev.created) - sb.unixSec(lastEvent.ev.created)
			if (elapsedSec > 300) curEvent.show_time = true // 5 mins
		}
	}

	for (let i = 0; i < events.length; i++) {
		let curEvent = events[i]
		if (lo.get(curEvent, 'ev.type') === 'message_sent') {
			curEvent.show_time = true
			break
		}
	}

	// group invite
	for (let i = 0; i < events.length; i++) {
		if (lo.get(events[i], 'ev.type') !== 'conversation_invited') continue
		if (
			lo.get(events[i - 1], 'ev.type') !== 'conversation_invited' ||
			lo.get(events[i - 1], 'ev.by.id') !== lo.get(events[i], 'ev.by.id')
		) {
			events[i].mode = 'lead'

			let followers = []
			for (let j = i + 1; j < events.length; j++) {
				let followEv = events[j]
				if (!visibleEvent(followEv.ev)) continue
				if (
					lo.get(followEv, 'ev.type') === 'conversation_invited' &&
					lo.get(followEv, 'ev.by.id') === lo.get(events[i], 'ev.by.id') &&
					lo.get(followEv, 'ev.data.conversation.members.0.type') === 'agent'
				) {
					followers.push(lo.get(followEv, 'ev.data.conversation.members.0.id'))
					continue
				}
				break
			}
			events[i].followers = followers.join(',')
		}
	}

	// insert lead
	let curEvent
	for (let i = 0; i < events.length; i++) {
		if (lo.get(events, i + '.ev.type') !== 'message_sent' && lo.get(events, i + '.ev.type') !== 'content_viewed')
			continue
		let prevEvent = curEvent
		curEvent = events[i]

		curEvent.mode = 'lead'
		let prevEvCreated = lo.get(prevEvent, 'ev.event_time') || lo.get(prevEvent, 'ev.created')
		let curEvCreated = lo.get(curEvent, 'ev.event_time') || lo.get(curEvent, 'ev.created')
		if (
			lo.get(prevEvent, 'ev.type') === 'message_sent' &&
			lo.get(prevEvent, 'ev.by.id') === lo.get(curEvent, 'ev.by.id') &&
			Math.abs(prevEvCreated - curEvCreated) <= 10 * 3_600_000
		) {
			curEvent.mode = 'follow'
		}
	}

	let seenLast
	let userids = sb.usersOfConvo(convo)
	let lastUserSeenMs = 0
	let seenByUser = ''
	let lastReceivedMs = 0
	if (!convo) convo = {}
	lo.map(convo.members, (m) => {
		if (m.type === 'user') {
			if (lastUserSeenMs < sb.getMs(m.seen_at)) {
				lastUserSeenMs = sb.getMs(m.seen_at)
				seenByUser = m.id
			}
			if (lastReceivedMs < sb.getMs(m.received_at)) lastReceivedMs = sb.getMs(m.received_at)
		}
	})

	// find last msg by me
	let lastSentOrSeenByUser = 0
	for (var i = events.length - 1; i >= 0; i--) {
		lastSentOrSeenByUser = i
		// break rightaway if go user message
		if (userids.includes(lo.get(events[i], 'ev.by.id'))) {
			seenByUser = lo.get(events[i], 'ev.by.id')
			break
		}

		if (sb.getMs(events[i].ev.created) <= lastUserSeenMs) {
			break
		}
	}

	let lastReceived = 0
	for (var i = events.length - 1; i >= 0; i--) {
		if (events[i].ev.type !== 'message_sent') continue
		if (userids.includes(lo.get(events[i], 'ev.by.id'))) continue

		lastReceived = i
		if (sb.getMs(events[i].ev.created) <= lastReceivedMs) {
			break
		}
	}

	for (let i = lastSentOrSeenByUser; i > 0; i--) {
		if (events[i].ev.type !== 'message_sent') continue
		if (userids.includes(lo.get(events[i], 'ev.by.id'))) break
		events[i].seen_last_by = seenByUser
		break
	}

	for (let i = events.length - 1; i > lastSentOrSeenByUser; i--) {
		events[i].receive = false
	}

	for (let i = lastSentOrSeenByUser; i <= lastReceived; i++) {
		events[i].receive = true
	}

	for (var i = 0; i < events.length; i++) {
		if (events[i].ev.type !== 'message_sent') continue
		if (userids.includes(lo.get(events[i], 'ev.by.id'))) continue

		let ackerr = lo.find(lo.get(events[i], 'ev.data.message.pongs'), (p) => {
			if (p.type !== 'ack') return false
			if (!p.ack_error) return false

			events[i].ev._error = p.ack_error
			return true
		})
	}
}

function visibleEvent(ev) {
	let type = lo.get(ev, 'type')
	return (
		type === 'conversation_tagged' ||
		type === 'conversation_untagged' ||
		type === 'conversation_invited' ||
		type === 'bot_terminated' ||
		type === 'conversation_left' ||
		type === 'message_sent' ||
		type === 'conversation_state_updated'
	)
}
